$color-default: #2d39b5;
$color-accent: #38b52d;
$text-color: #363636;
$color-black: #000;
$color-white: #fff;
$color-grey-light: rgba(0, 0, 0, 0.1);
$font-varela: 'Varela Round', sans-serif;
$font-sub: 'Varela Round', sans-serif;
$transition: 0.3s ease;
$shadow-big: 0 0.1rem 2rem rgba(0, 0, 0, 0.15);
$shadow-small: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08);
