/********
--- A ---
********/

/*------ Additional Information list ------*/
.additional-info {
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  padding-top: 1.8rem;
  ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    li {
      figure {
        opacity: 0.6;
        font-size: 1.1rem;
        text-transform: uppercase;
        float: left;
        padding: 0.2rem 0;
        font-weight: 600;
      }
      aside {
        font-size: 1.1rem;
        padding: 0.2rem 0;
        text-align: right;
      }
    }
  }
}

.answer {
  margin-bottom: 50px;
  .box {
    padding-top: 40px;
    position: relative;
    &:after {
      box-shadow: $shadow-small;
      border-radius: 100%;
      content: '?';
      color: #fff;
      font-weight: bold;
      background-color: $color-default;
      height: 30px;
      width: 30px;
      line-height: 28px;
      text-align: center;
      position: absolute;
      font-size: 16px;
      top: -15px;
    }
    &:before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 4px 0 4px;
      border-color: $color-default transparent transparent transparent;
      content: '';
      position: absolute;
      left: 41px;
      top: 11px;
    }
  }
  h3 {
    margin-bottom: 10px;
  }
  figure {
    opacity: 0.5;
    text-align: right;
    font-size: 12px;
    margin-top: 10px;
    a {
      margin-left: 10px;
      color: inherit;
    }
    i {
      font-size: 10px;
      margin-left: 5px;
    }
  }
}

.alert {
  &.alert-warning {
    background-color: #fffde3;
    border: none;
    box-shadow: inset 0 0 0 0.1rem rgba(0, 0, 0, 0.1);
    border-radius: 0.4rem;
    padding: 3rem 4rem;
    h2 {
      margin-bottom: 1rem;
    }
    h4 {
      padding-top: 1rem;
    }
  }
}

.authors {
  &.grid,
  &.masonry {
    .author.item {
      .wrapper {
        background-color: #f8f8f8;
        .image {
          .image-wrapper {
            border-color: #fff;
          }
        }
      }
      .meta {
        background-color: #fff;
      }
      .additional-info {
        display: block;
      }
      h3 {
        bottom: 3.5rem;
      }
      h4 {
        top: 22.5rem;
      }
    }
  }
  .author.item {
    .meta {
      figure {
        .rating {
          margin-bottom: 0;
          i {
            margin-right: 0;
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}

.author {
  .author-image {
    width: 6rem;
    height: 6rem;
    float: left;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
  }
  .author-description {
    padding-top: 1rem;
    margin-left: 8rem;
  }
  &.big {
    padding-bottom: 5rem;
    .author-image {
      width: 26rem;
      height: 26rem;
      box-shadow: $shadow-big;
    }
    .author-description {
      padding-top: 1rem;
      margin-left: 30rem;
      h2 {
        font-size: 3rem;
      }
      h4 {
        margin-bottom: 1.5rem;
        opacity: 0.5;
      }
      .rating {
        i {
          font-size: 1.2rem;
        }
      }
      .section-title {
        padding-bottom: 2rem;
      }
      .additional-info {
        padding: 2rem;
        background-color: #fff;
        border-radius: 0.3rem;
        margin-bottom: 2rem;
        ul {
          li {
            display: inline-block;
            margin-right: 2rem;
            figure {
              float: none;
              padding: 0;
              margin: 0;
            }
            aside {
              opacity: 1;
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}

/********
--- B ---
********/

.blockquote {
  font-size: 1.8rem;
}

.breadcrumb {
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: 1.1rem;
  text-transform: uppercase;
}

.blog-post {
  border-radius: 0.4rem;
  box-shadow: $shadow-small;
  overflow: hidden;
  margin-bottom: 4rem;
  .article-title {
    background-color: #f8f8f8;
    padding: 3rem;
    h2 {
      margin-bottom: 1rem;
    }
  }
  img {
    max-width: 100%;
  }
  .blog-post-content,
  .meta {
    padding: 3rem;
    background-color: #fff;
  }
  .meta {
    font-size: 1.1rem;
    padding-bottom: 0;
    figure {
      display: inline-block;
      margin-right: 1rem;
      opacity: 0.8;
    }
  }
  p {
    margin-bottom: 1rem;
  }
  .detail {
    margin-top: 2rem;
  }
  .author {
    .section-title {
      padding-bottom: 1rem;
    }
    .author-image {
      width: 10rem;
      height: 10rem;
    }
    .author-description {
      margin-left: 13rem;
    }
  }
}
.blog-posts-navigation {
  position: relative;
  i {
    color: $color-default;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 1rem;
    margin: auto;
  }
  figure {
    opacity: 0.5;
    margin-bottom: 0;
  }
  .prev,
  .next {
    position: relative;
  }
  .prev {
    padding-left: 3rem;
    display: inline-block;
    i {
      left: 0;
    }
  }
  .next {
    padding-right: 3rem;
    display: inline-block;
    text-align: right;
    float: right;
    i {
      right: 0;
    }
  }
}

/********
--- C ---
********/

.categories-list {
  list-style: none;
  padding-left: 0;
  li {
    position: relative;
    padding-left: 8rem;
    padding-top: 1rem;
    float: left;
    width: 25%;
    margin-bottom: 4rem;
    h3 {
      margin-bottom: 0.5rem;
    }
    .sub-categories {
      a {
        font-size: 1.3rem;
        margin-right: 0.3rem;
        opacity: 0.6;
        transition: 0.3s ease;
        &:hover {
          opacity: 1;
        }
        &:after {
          content: ',';
        }
        &:last-child {
          &:after {
            content: '...';
          }
        }
      }
    }
    i {
      position: absolute;
      width: 6rem;
      height: 6rem;
      text-align: center;
      line-height: 5.8rem;
      background-color: $color-default;
      box-shadow: $shadow-small;
      border-radius: 50%;
      left: 0;
      top: 0;
      img {
        height: 3rem;
        opacity: 0.8;
        filter: invert(100%);
      }
    }
  }
}

.comments {
  .comment {
    margin-bottom: 3rem;
    .author {
      .author-description {
        margin-left: 10rem;
        border-bottom: 0.1rem solid $color-grey-light;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        h3,
        h4 {
          font-size: 1.6rem;
          font-weight: 500;
          font-family: $font-sub;
          margin-bottom: 1rem;
        }
        h5 {
          font-weight: 600;
          display: inline-block;
          a {
            display: inline-block;
          }
        }
        .meta {
          font-size: 1.1rem;
          margin-bottom: 2rem;
          span {
            margin-right: 1rem;
          }
          span:not(.rating) {
            opacity: 0.5;
          }
        }
      }
      .author-image {
        box-shadow: $shadow-small;
      }
    }
  }
  &.masonry {
    .comment {
      .author {
        background-color: #fff;
        box-shadow: $shadow-big;
        border-radius: 0.4rem;
        padding: 3rem;
      }
      .author-description {
        margin-left: 0;
        padding-top: 0;
        border-bottom: none;
        h3 {
          position: inherit;
          color: inherit;
        }
        .meta {
          background-color: transparent;
          padding: 0;
          &:before {
            display: none;
          }
        }
      }
      h5 {
        line-height: 6rem;
        margin-left: 8rem;
      }
    }
  }
}

.bg-color-accent {
  background-color: $color-accent !important;
}

/********
--- E ---
********/

.elements-grid {
  [class*='col'],
  [class*='col-'] {
    div {
      background-color: $color-grey-light;
      text-align: center;
      border: 0.1rem solid rgba(0, 0, 0, 0.1);
      border-radius: 0.2rem;
    }
  }
}

/********
--- F ---
********/

.feature-box {
  margin-bottom: 3rem;
  figure {
    background-color: $color-default;
    border-radius: 50%;
    width: 9rem;
    height: 9rem;
    text-align: center;
    line-height: 8.5rem;
    position: relative;
    margin-bottom: 2rem;
    img {
      height: 3rem;
      opacity: 0.8;
      filter: invert(100%);
    }
    span {
      display: block;
      position: absolute;
      background-color: #fff;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      text-align: center;
      line-height: 3rem;
      top: 0;
      box-shadow: $shadow-big;
      right: -0.5rem;
      font-weight: 800;
    }
    h3 {
      margin-bottom: 1rem;
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 12rem;
  padding-bottom: 10rem;
  .background {
    background-color: $color-white;
  }
  .brand {
    margin-bottom: 4rem;
    display: block;
  }
  nav {
    ul {
      line-height: 3rem;
      opacity: 0.8;
    }
  }
  address {
    opacity: 0.8;
  }
}

/********
--- H ---
********/
.hero {
  z-index: 3;
  background-color: #fff; /*background-image: url("../../assets/img/hero-overlay.png"); background-size: contain; background-position: bottom center; background-repeat: no-repeat; padding-bottom: 4rem; position: relative; background-color:#fff; z-index: 3;*/
  &:after {
    background-image: url('../../assets/img/hero-overlay.png');
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
    position: absolute;
    content: '';
    width: 100%;
    height: 4.8rem;
    bottom: 0;
  }
  .hero-wrapper {
    padding-bottom: 7rem;
  }
  .main-navigation {
    z-index: 999;
    position: relative;
    .navbar {
      border-bottom: 0.1rem solid $color-grey-light;
      padding: 3rem 0;
      margin-bottom: 1rem;
      /*------ Main navigation list ------*/
      ul.navbar-nav {
        position: absolute;
        right: 0;
        a:not(.btn) {
          padding: 0.5rem 1.7rem;
        }
        .btn {
          margin-left: 1.5rem;
          padding: 0.8rem 1.6rem;
        }
        li.nav-item {
          position: relative;
          &.has-child {
            > a.nav-link {
              &:after {
                font-family: 'fontawesome';
                position: absolute;
                font-size: 1.2rem;
                color: rgba(0, 0, 0, 0.25);
                top: 0;
                bottom: 0;
                margin: auto;
                height: 1.6rem;
              }
            }
            &:hover {
              > a.nav-link {
                &:after {
                  color: $color-default;
                }
              }
            }
          }
        }
        /*------ Main navigation list item ------*/
        > li.nav-item {
          &.has-child {
            &:hover {
              /*------ 1st level list ------*/
              > ul.child {
                opacity: 1;
                pointer-events: auto;
                transform: translateY(0rem);
                &:before {
                  pointer-events: auto;
                }
                &:after {
                  border-style: solid;
                  border-width: 0 0.45rem 0.6rem 0.45rem;
                  border-color: transparent transparent $color-white transparent;
                  position: absolute;
                  top: -0.6rem;
                  right: 1.8rem;
                  content: '';
                }
              }
            }
            > a.nav-link {
              &:after {
                content: '\f0d7';
                right: 0.4rem;
              }
            }
          }
          /*------ 1st level list ------*/
          > ul.child {
            margin-top: 1.5rem;
            &:before {
              position: absolute;
              width: 100%;
              height: 1.5rem;
              background-color: transparent;
              content: '';
              top: -1.5rem;
              right: 0;
            }
            > li {
              &:hover {
                /*------ 2nd level ------*/
                > ul.child {
                  opacity: 1;
                  pointer-events: auto;
                  transform: translateX(0rem);
                  > li {
                    &:hover {
                      /*------ 3rd level ------*/
                      > ul.child {
                        opacity: 1;
                        pointer-events: auto;
                        transform: translateX(0rem);
                        > li {
                          &:hover {
                            /*------ 4th level ------*/
                            > ul.child {
                              opacity: 1;
                              pointer-events: auto;
                              transform: translateX(0rem);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ul.child {
            box-shadow: 0 0.1rem 2rem rgba(0, 0, 0, 0.15);
            border-radius: 0.3rem;
            transform: translateY(0.3rem);
            opacity: 0;
            pointer-events: none;
            transition: $transition;
            position: absolute;
            right: 0;
            width: 20rem;
            background-color: #fff;
            text-align: right;
            &:hover {
              pointer-events: auto;
            }
            /*------ 1st level and next levels list item ------*/
            li {
              border-bottom: 0.1rem solid rgba(0, 0, 0, 0.04);
              transition: $transition;
              &:hover {
                background-color: rgba(0, 0, 0, 0.02);
              }
              &.has-child {
                > a.nav-link {
                  &:after {
                    content: '\f0d9';
                    left: 1rem;
                  }
                }
              }
              a.nav-link {
                padding: 1rem 1.5rem;
              }
              /*------ 2nd and next levels ------*/
              ul {
                right: 20rem;
                top: 0;
                transform: translateX(0.3rem);
                margin-right: 0.5rem;
                li {
                  /*----- Small right arrow on first list item ------*/
                  &:first-child {
                    &:after {
                      background-color: transparent;
                      width: 0.5rem;
                      height: 100%;
                      content: '';
                      position: absolute;
                      right: -0.5rem;
                      top: 0;
                    }
                    &:before {
                      border-style: solid;
                      border-width: 0.45rem 0 0.45rem 0.6rem;
                      border-color: transparent transparent transparent $color-white;
                      content: '';
                      position: absolute;
                      top: 1.6rem;
                      right: -0.6rem;
                      z-index: 1;
                    }
                    &:hover {
                      &:before {
                        border-color: transparent transparent transparent #fafafa;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .main-search-form-toggle {
      box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
      position: absolute;
      right: 0;
      bottom: -4.1rem;
      background-color: $color-default;
      padding: 1rem 1.4rem;
      color: $color-white;
      border-bottom-left-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
      &:hover {
        box-shadow: 0 0.4rem 3.3rem rgba(0, 0, 0, 0.3);
      }
      i {
        transition: $transition;
        &.fa-close {
          opacity: 0;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 1.7rem;
          right: 0;
          left: 0;
          width: 1rem;
        }
      }
      &[aria-expanded='true'] {
        .fa-close {
          opacity: 1;
        }
        .fa-search {
          opacity: 0;
        }
      }
    }
  }

  .secondary-navigation {
    background-color: #363636;
    color: $color-white;
    font-size: 1.1rem;
    font-weight: 500;
    display: table;
    width: 100%;
    height: 24px;
    a,
    span {
      color: $color-white;
      padding: 0.4rem;
      display: inline-block;
      text-decoration: none;
    }
    a {
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    i {
      opacity: 0.5;
      margin-right: 0.5rem;
    }
    .left {
      float: left;
      & li {
        &:first-child {
          padding-left: 0;
        }
      }
    }
    .right {
      float: right;
      li {
        border-left: 0.1rem solid rgba(255, 255, 255, 0.2);
        &:last-child {
          border-right: 0.1rem solid rgba(255, 255, 255, 0.2);
        }
      }
    }
    ul {
      li {
        float: left;
      }
    }
  }

  &.has-map {
    .hero-form {
      padding-top: 8rem;
      padding-bottom: 6rem;
      box-shadow: 0rem -0.5rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
      z-index: 1;
      position: relative;
    }
    .hero-wrapper {
      padding-bottom: 0;
    }
    .main-navigation {
      box-shadow: $shadow-small;
      .navbar {
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .page-title {
    padding-top: 5rem;
    padding-bottom: 3rem;
    a.title-link {
      position: relative;
      &:hover::after {
        animation-name: underline-animation;
        animation-duration: 0.7s;
        animation-fill-mode: forwards; /*background-color: $color-default; transition: $transition;*/
      }
      &:after {
        background-color: $color-black;
        width: 100%;
        height: 0.2rem;
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    h1 {
      .tag {
        vertical-align: middle;
        position: relative;
        top: -0.3rem;
        margin-left: 1rem;
        background-color: $color-default;
        color: $color-white;
      }
    }
    h4 {
      opacity: 0.5;
      &.location {
        &:before {
          font-family: 'fontawesome';
          color: $color-black;
        }
      }
      a {
        &:after {
          display: none;
        }
      }
    }
    .price {
      text-align: right;
      .number {
        font-family: $font-varela;
        color: $color-accent;
        font-size: 3.6rem;
        line-height: 1.1;
      }
    }
  }
}

/********
--- I ---
********/

/*------ Items ------*/
.items:not(.selectize-input) {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  &:after {
    clear: both;
    content: '';
    display: block;
  }

  /*------ Item ------*/
  .item {
    margin-bottom: 3rem;
    position: relative;
    .wrapper {
      overflow: hidden;
      box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
      transition: 0.3s box-shadow ease, 0.3s transform ease;
      border-radius: 0.5rem;
      background-color: $color-white;
      position: relative;
      transform: translateY(0); /*padding-bottom: 5rem;*/
      &:hover {
        box-shadow: 0 0.4rem 3.3rem rgba(0, 0, 0, 0.3);
        transform: translateY(-0.2rem);
      }
    }
    /*------ Call to action item ------*/
    &.call-to-action {
      text-align: center;
      height: 30rem;
      display: block;
      .wrapper {
        background-color: transparent;
        box-shadow: none;
        display: table;
        width: 100%;
        height: 100%;
        border: 0.1rem solid rgba(0, 0, 0, 0.05);
        transition: 0.3s box-shadow ease, 0.3s background-color ease, 0.3s transform ease;
        padding-bottom: 0;
        &:hover {
          background-color: rgba(0, 0, 0, 0.03);
        }
        .title {
          color: $color-black;
          font-size: 1.3rem;
          display: table-cell;
          vertical-align: middle;
          font-family: $font-varela;
          i {
            display: block;
            font-size: 2.4rem;
            color: $color-default;
            margin-bottom: 1rem;
          }
          .cta {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1rem;
            width: 10rem;
          }
        }
      }
    }

    /*------ Image ------*/
    .image {
      height: 26rem;
      position: relative;
      border-top-left-radius: 0.6rem;
      border-top-right-radius: 0.6rem;
      background-color: #f8f8f8;
      .image-wrapper {
        transition: none;
        display: block;
        position: relative;
        z-index: 0;
        &:before {
          opacity: 0.8;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 8rem;
          content: '';
          background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
          background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=0 );
        }
      }
    }

    /*------ Tags ------*/
    .tag, h3, h4, .price /*, .meta*/ {
      position: absolute;
    }
    .tag {
      color: $color-white;
      position: absolute;
      z-index: 1;
      left: 1.8rem;
      top: 12rem;
      transition: 0.3s background-color ease;
      padding: 0.4rem 0.5rem;
    }

    /*------ H3 heading ------*/
    h3 {
      position: absolute;
      z-index: 1;
      left: 1.8rem;
      color: white;
      bottom: 6rem;
      padding-right: 1.8rem;
      margin-bottom: 0.5rem;
      a {
        color: $color-white;
        transition: none;
        text-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.6);
        display: block;
        &:hover {
          text-decoration: underline;
        }
        &.category {
          display: inline-block;
          color: $color-white;
          position: relative;
          transition: 0.3s background-color ease;
          font-weight: 600;
          font-size: 1rem;
          text-shadow: none;
          text-decoration: none;
          top: inherit;
          bottom: 1rem;
          left: 0;
        }
      }
      .tag:not(.category) {
        background-color: $color-white;
        top: inherit;
        bottom: 16rem;
        left: 0;
        color: $color-black;
        font-weight: 600;
        font-size: 1rem;
        opacity: 0.6;
        transition: none;
        pointer-events: none;
      }
    }

    /*------ H4 heading ------*/
    h4 {
      position: absolute;
      z-index: 1;
      left: 1.8rem;
      color: white;
      top: 20rem;
      font-weight: 300;
      font-size: 1.4rem;
      opacity: 0.8;
      a {
        color: $color-white;
        transition: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &.location {
        &:before {
          color: $color-white;
        }
      }
    }

    /*------ Price ------*/
    .price {
      background-color: #f8f8f8;
      font-size: 1.6rem;
      font-weight: 600;
      position: absolute;
      top: 22.6rem;
      left: 1.8rem;
      padding: 0.5rem 1.8rem;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
      z-index: 1;
      .appendix {
        font-size: 1rem;
        margin-right: 0.6rem;
        opacity: 0.7;
        top: -0.2rem;
        position: relative;
        display: block;
        margin-bottom: -0.7rem;
      }
    }

    /*------ Meta ------*/
    .meta {
      background-color: #f8f8f8;
      padding: 1.8rem;
      font-size: 1.2rem;
      width: 100%;
      white-space: nowrap;
      margin-top: -0.2rem;
      position: relative;
      &:before {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f8f8f8+0,f8f8f8+100&0+0,1+50 */
        background: -moz-linear-gradient(left, rgba(248, 248, 248, 0) 0%, rgba(248, 248, 248, 1) 50%, rgba(248, 248, 248, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(248, 248, 248, 0) 0%, rgba(248, 248, 248, 1) 50%, rgba(248, 248, 248, 1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to right,
          rgba(248, 248, 248, 0) 0%,
          rgba(248, 248, 248, 1) 50%,
          rgba(248, 248, 248, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f8f8f8', endColorstr='#f8f8f8',GradientType=1 ); /* IE6-9 */

        height: 100%;
        width: 4rem;
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }
      figure {
        opacity: 0.6;
        margin-right: 2rem;
        display: inline-block;
        i {
          margin-right: 1rem;
        }
        a {
          transition: 0.3s color ease;
        }
      }
    }

    /*------ Description ------*/
    .description {
      font-size: 1.3rem;
      padding: 1.8rem 1.8rem 0 1.8rem;
      p {
        margin-bottom: 0;
      }
    }

    /*------ Detail link ------*/
    .detail {
      bottom: 1.8rem;
      left: 1.8rem;
      color: $color-default;
      transition: 0.3s background-color ease;
      position: absolute;
      &:after {
        background-color: $color-default;
      }
    }

    figure {
      margin-bottom: 0;
    }
    &.author {
      .meta {
        &:before {
          display: none;
        }
        &:after {
          display: block;
          clear: both;
          content: '';
        }
        figure {
          margin-right: 0;
          float: left;
          &:last-child {
            float: right;
          }
        }
      }
    }
  }

  /*------------------*/
  /*- Grid, Masonry --*/
  /*------------------*/
  &.grid,
  &.masonry {
    /*------ Border around image ------*/
    .item {
      .image {
        .image-wrapper {
          border: 0.6rem solid #f8f8f8;
          border-radius: 0.9rem;
        }
      }
    }
    .item {
      float: left;
      .wrapper {
        float: left;
      }
      p {
        height: 6rem;
        overflow: hidden;
      }
    }
  }

  &.masonry {
    .item {
      .wrapper {
        padding-bottom: 5rem;
      }
    }
  }
  /*------------------*/
  /*----- Grid -------*/
  /*------------------*/
  &.grid {
    .item {
      height: 43rem;
      .additional-info {
        display: none;
      }
    }
    &.compact {
      .item {
        height: inherit;
        .wrapper {
          height: 31rem;
          .detail {
            display: none;
          }
        }
        .admin-controls {
          position: relative;
          right: inherit;
          text-align: center;
          height: auto;
          border-left: none;
          padding-left: 0;
          padding-right: 0;
          padding-top: 1.2rem;
          a {
            display: inline-block;
            padding: 0.5rem;
            i {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }

  /*------------------*/
  /*------ List ------*/
  /*------------------*/
  &.list {
    height: inherit !important;

    /*------  Border around image ------*/
    .item {
      .image {
        .image-wrapper {
          border: 0.6rem solid $color-white;
          border-radius: 0.9rem;
        }
      }
    }

    /*------ Item ------*/
    .item {
      position: relative !important;
      top: inherit !important;
      left: inherit !important;
      .wrapper {
        min-height: 18rem;
        padding-bottom: 0;
      }
      .image {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: transparent;
        .background-image {
          width: 25rem;
        }
      }

      /*------ H3 ------*/
      h3 {
        top: 2.5rem;
        font-size: 2.2rem;
        color: $color-black;
        left: 27rem;
        bottom: inherit;
        width: 100%;
        a:not(.category) {
          color: $color-black;
          text-shadow: none;
          display: inline-block;
          &:hover {
            text-decoration: none;
            color: $color-default;
          }
        }
        .tag:not(.category) {
          position: relative;
          top: -0.2rem;
          background-color: transparent;
          border: 0.1rem solid rgba(0, 0, 0, 0.3);
          vertical-align: middle;
        }
        .tag.category {
          position: absolute;
          color: $color-white;
          bottom: inherit;
          top: -1rem;
          left: -25.5rem;
        }
      }

      /*------ H4 ------*/
      h4 {
        top: 5.5rem;
        left: 27rem;
        a {
          color: $color-black;
        }
        &.location {
          &:before {
            font-family: 'fontawesome';
            color: $color-black;
          }
        }
      }

      /*------ Price ------*/
      .price {
        top: inherit;
        border-radius: 0.3rem;
        bottom: 2rem;
      }

      /*------ Tag ------*/
      .tag {
        top: 2rem;
      }

      /*------ Meta ------*/
      .meta {
        position: absolute;
        padding: 3rem 2rem 0 0;
        right: 0;
        width: auto;
        font-size: 1.1rem;
        background-color: transparent;
        text-align: right;
        top: 0;
        z-index: 1;
        &:before {
          display: none;
        }
        figure {
          display: block;
          margin-right: 0;
        }
      }

      /*------ Description ------*/
      .description {
        position: absolute;
        bottom: 2rem;
        padding: 0;
        left: 27rem;
        z-index: 1;
        height: 4rem;
        overflow: hidden;
        p {
          width: 80%;
        }
      }

      /*------ Detail ------*/
      .detail {
        right: 2rem;
        bottom: 2rem;
        left: inherit;
        border: 0.1rem solid $color-default;
        padding: 1rem;
        text-transform: none;
        font-size: 1.4rem;
        border-radius: 0.3rem;
        z-index: 2;
        &:hover {
          background-color: $color-default;
          color: $color-white;
        }
        &:after {
          display: none;
        }
      }

      /*------ Additional Info ------*/
      .additional-info {
        padding: 9rem 0 8rem 0;
        margin-right: 2rem;
        position: relative;
        margin-left: 27rem;
        ul {
          background-color: #f9f9f9;
          border-radius: 0.3rem;
          margin-bottom: 0;
          padding: 1.5rem;
          li {
            display: inline-block;
            margin-right: 5rem;
            figure {
              float: none;
            }
            aside {
              text-align: left;
            }
          }
        }
      }
      &.call-to-action {
        height: 15rem;
      }
    }

    /*-------------------------*/
    /*-- Compact Item - List --*/
    /*-------------------------*/
    &.compact {
      .item {
        min-height: 17rem;
        .image {
          padding-right: 20rem;
          .background-image {
            width: 20rem;
          }
          .image-wrapper {
            &:before {
              opacity: 0.6;
              height: 8rem;
            }
          }
        }
        .additional-info {
          margin-left: 22rem;
          padding: 8rem 0 6.3rem 0;
          ul {
            padding: 0.4rem 0.4rem 0 0.4rem; /*background-color: transparent;*/
            li {
              figure {
                padding: 0;
                margin-bottom: -0.4rem;
                font-size: 1rem;
              }
            }
          }
        }
        h3,
        h4,
        .description {
          left: 22rem;
        }
        h3 {
          .tag.category {
            left: -20.3rem;
          }
        }
        .price {
          padding: 0.3rem 1.1rem;
          font-size: 1.3rem;
        }
        .description {
          padding-right: 20rem;
          bottom: 1.4rem;
          p {
            font-size: 1.2rem;
          }
        }
        .detail {
          border: inherit;
          text-transform: uppercase;
          font-size: 1.1rem;
          bottom: 2rem;
          padding: 0;
          &:hover {
            background-color: transparent;
            color: $color-default;
          }
          &:after {
            display: block;
          }
        }
        .admin-controls {
          position: absolute;
          right: 0;
          height: 8rem;
          top: 0;
          bottom: 0;
          margin: auto;
          border-left: 0.1rem solid $color-grey-light;
          padding-left: 2rem;
          padding-right: 2rem;
          z-index: 1;
          background-color: #fff;
          a {
            display: block;
            padding: 0.4rem 0;
            i {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
  .item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    .wrapper {
      width: 100%;
      height: 100%;
    }
    .admin-controls {
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 600;
      a {
        display: inline-block;
        padding: 0.5rem;
        i {
          margin-right: 0.5rem;
          opacity: 0.5;
        }
      }
    }
  }
}

.logo {
  width: 135px;
}

.map {
  &#map-small {
    box-shadow: $shadow-big;
    border-radius: 0.5rem;
    display: block;
  }
  &#map-contact {
    margin-top: -5rem;
    margin-bottom: 5rem;
    &:before {
      background-image: url('../../assets/img/footer-overlay.png');
      background-size: contain;
      background-position: top center;
      background-repeat: no-repeat;
      padding-bottom: 10rem;
      position: absolute;
      content: '';
      width: 100%;
      height: auto;
      z-index: 1;
      pointer-events: none;
    }
  }
  .cluster {
    > div {
      color: $color-white !important;
    }
  }
  .marker {
    cursor: pointer;
    opacity: 0.7;
    transition: $transition;
    transform: translateY(0.2rem);
    &:hover {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .infobox-wrapper {
    position: relative;
    display: inline-block;
    > img {
      position: absolute !important;
      top: -1.5rem;
      right: -1.5rem;
      z-index: 1;
    }
    &:after {
      background-image: url('../../assets/img/infobox-arrow.png');
      content: '';
      display: block;
      width: 100%;
      height: 1.6rem;
      background-position: center;
      background-repeat: no-repeat;
    }
    .ribbon-featured {
      right: 0;
      background-color: $color-default;
      padding: 0.5rem;
      text-transform: uppercase;
      font-weight: 700;
      color: #fff;
      border-radius: 0.4rem;
      box-shadow: $shadow-small;
      top: 2.5rem;
    }
    a {
      &:hover {
        color: inherit;
      }
    }
    .infobox {
      width: 25rem;
      background-color: #f8f8f8;
      padding: 0.5rem;
      border-radius: 0.6rem;
      overflow: hidden;
      box-shadow: $shadow-big;
      .image-wrapper {
        width: 100%;
        height: 20rem;
        overflow: hidden;
        border-radius: 0.4rem;
        position: relative;
        > .tag,
        h3,
        .price {
          position: absolute;
          left: 2rem;
          z-index: 1;
        }
        .price {
          bottom: -0.3rem;
          background-color: #f8f8f8;
          font-size: 1.6rem;
          font-weight: 600;
          padding: 0.5rem 1.8rem;
          border-top-left-radius: 0.3rem;
          border-top-right-radius: 0.3rem;
          z-index: 1;
          .appendix {
            font-size: 1rem;
            margin-right: 0;
            opacity: 0.7;
            top: -0.2rem;
            position: relative;
            display: block;
            margin-bottom: -0.7rem;
            margin-left: 0;
          }
        }
        .type {
          background-color: #fff;
          opacity: 0.8;
          top: 2rem;
        }
        h3 {
          bottom: 2rem;
          color: #fff;
          span:not(.tag) {
            display: block;
            margin-top: 0.8rem;
          }
        }
        .image {
          height: 100%;
          width: 100%;
          background-size: cover;
          position: relative;
          &:before {
            opacity: 0.8;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 15rem;
            content: '';
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=0 );
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }
}

/********
--- N ---
********/

.nav-tabs,
.nav-pills {
  .nav-link {
    padding: 1rem 2rem;
  }
}
.nav-pills {
  .nav-link {
    &.active {
      background-color: $color-default;
    }
  }
}
.tab-content {
  padding-top: 2rem;
}

/********
--- O ---
********/

.owl-carousel {
  &.full-width-carousel {
    position: relative;
    top: -5.5rem;
    margin-bottom: -5.5rem;
    &:before {
      background-image: url('../../assets/img/footer-overlay.png');
      background-size: contain;
      background-position: top center;
      background-repeat: no-repeat;
      padding-bottom: 10rem;
      position: absolute;
      content: '';
      width: 100%;
      height: auto;
      z-index: 1;
    }
    .item {
      width: 111rem;
      border-radius: 0.4rem;
      height: 70rem;
      overflow: hidden;
    }
    .owl-item {
      opacity: 0.2;
      transition: 1s opacity ease;
      &.active.center {
        opacity: 1;
      }
    }
  }
  &.gallery-carousel {
    margin-bottom: 3rem;
  }
  &.gallery-carousel,
  &.full-width-carousel {
    .owl-stage-outer {
      border-radius: 0.4rem;
    }
    .owl-nav {
      .owl-prev,
      .owl-next {
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 5rem;
        i {
          background-color: $color-default;
          width: 5rem;
          text-align: center;
          line-height: 5rem;
          color: $color-white;
          box-shadow: $shadow-small;
          border-radius: 50%;
        }
      }
      .owl-prev {
        left: 0;
        i {
          margin-left: 2rem;
        }
      }
      .owl-next {
        right: 0;
        margin-right: 2rem;
      }
    }
  }
  &.gallery-carousel-thumbs {
    .owl-thumb {
      border-radius: 0.4rem;
      height: 13rem;
      display: block;
      opacity: 0.3;
      transition: $transition;
      &:hover {
        opacity: 1;
      }
      img {
        width: 100%;
        display: none;
      }
      &.active-thumb {
        box-shadow: inset 0 0 0 0.3rem $color-default;
        opacity: 1;
      }
    }
  }
  .owl-dots {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    .owl-dot {
      display: inline-block;
      span {
        background-color: $color-black;
        opacity: 0.3;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        display: inline-block;
        margin: 0 0.5rem;
        transition: $transition;
      }
      &.active {
        span {
          opacity: 0.8;
        }
      }
    }
  }
}

/********
--- P ---
********/

.pac-logo {
  &:after {
    display: none;
  }
}

.page {
  .hero-wrapper {
    position: relative;
    .background {
      background-color: #fff;
    }
  }
  &.sub-page {
    .page-title {
      padding-top: 3rem;
      padding-bottom: 1rem;
    }
    .form {
      &.hero-form {
        padding-top: 2rem;
        .main-search-form {
          background-color: #f2f2f2;
          padding: 5rem 3rem 1rem 3rem;
          border-radius: 0.3rem;
          margin-bottom: 1rem;
          position: relative;
          &:before {
            border-style: solid;
            border-width: 0 0.45rem 0.6rem 0.45rem;
            border-color: transparent transparent #f2f2f2 transparent;
            position: absolute;
            top: -0.6rem;
            right: 1.5rem;
            content: '';
          }
          .form-group {
            label {
              font-size: 1.8rem;
              top: -3.7rem;
            }
          }
          .geo-location.input-group-addon {
            height: 3.9rem;
          }
        }
      }
    }
  }
  > .content {
    background-color: #f2f2f2;
    z-index: 1;
    &:after {
      background-image: url('../../assets/img/footer-overlay.png');
      background-size: contain;
      background-position: top center;
      background-repeat: no-repeat;
      width: 100%;
      height: 4.2rem;
      left: 0;
      content: '';
      position: absolute;
    }
  }
}

.panel {
  background-color: #fff;
  box-shadow: $shadow-small;
  margin-bottom: 30px;
  .panel-title {
    margin-bottom: 0;
    a {
      display: block;
      padding: 20px;
      i {
        margin-right: 5px;
      }
    }
  }
  .panel-body {
    padding: 20px;
  }
  .horizontal-input-title {
    margin-top: 15px;
  }
}

.page-pagination {
  text-align: center;
  margin: 6rem 0 3rem 0;
  > nav {
    display: inline-block;
    .pagination {
      margin-bottom: 0;
      .page-item {
        &.active {
          .page-link {
            background-color: $color-default;
            color: $color-white;
            box-shadow: $shadow-big;
          }
        }
        .page-link {
          color: $color-black;
          width: 4rem;
          line-height: 4rem;
          padding: inherit;
          border: none;
          background-color: transparent;
          border-radius: 0.3rem;
          font-size: 1.6rem;
          margin: 0 0.2rem;
          &:hover {
            background-color: $color-white;
            box-shadow: $shadow-small;
          }
          i {
            font-size: 1.2rem;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
}

.pricing {
  position: relative;
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: center;
  padding: 30px 20px;
  &.box {
    background-color: $color-white;
    &.featured {
      background-color: $color-black;
      color: #fff;
      ul {
        li.available,
        li.not-available,
        li {
          color: #fff;
          border-bottom-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
  &.featured:not(.box) {
    figure {
      background-color: $color-default;
      color: #fff;
    }
  }
  &.description {
    box-shadow: none;
    background-color: transparent;
    text-align: left;
    padding-left: 0;
  }
  h2 {
    opacity: 0.7;
    color: inherit;
    font-size: 30px;
    font-weight: lighter;
  }
  figure {
    box-shadow: $shadow-small;
    border-radius: 100%;
    background-color: #ededed;
    width: 75px;
    height: 75px;
    position: absolute;
    top: 5px;
    right: -10px;
    text-align: center;
    line-height: 75px;
    color: $color-default;
    font-size: 18px;
    font-weight: bold;
  }
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 20px;
    margin-top: 20px;
    li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      line-height: 55px;
      &:last-child {
        border-bottom: none;
      }
      &.available {
        color: $color-default;
      }
      &.not-available {
        color: rgba(0, 0, 0, 0.5);
      }
      i {
        font-size: 20px;
      }
    }
  }
}

.profile-image {
  .image {
    width: 100%;
    height: 25.5rem;
    border-radius: 50%;
    box-shadow: $shadow-small;
    overflow: hidden;
    text-align: center;
    img {
      height: 100%;
    }
  }
}

/********
--- R ---
********/

.rating {
  margin-bottom: 0.5rem;
  i {
    font-size: 1rem;
    opacity: 0.4;
    padding: 0 0.1rem;
    &.active {
      color: $color-default;
      opacity: 1;
    }
  }
}

.read-more {
  overflow: hidden;
  transition: 1s ease;
  padding: 2rem 2rem 3rem 2rem;
  margin-right: -2rem;
  margin-left: -2rem;
  margin-bottom: 2rem;
  position: relative;
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 10rem;
    width: 100%;
    content: '';
    pointer-events: none;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f2f2f2+0,f2f2f2+100&0+0,1+66 */
    background: -moz-linear-gradient(top, rgba(242, 242, 242, 0) 0%, rgba(242, 242, 242, 1) 66%, rgba(242, 242, 242, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(242, 242, 242, 0) 0%, rgba(242, 242, 242, 1) 66%, rgba(242, 242, 242, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(242, 242, 242, 0) 0%,
      rgba(242, 242, 242, 1) 66%,
      rgba(242, 242, 242, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f2f2f2', endColorstr='#f2f2f2',GradientType=0 ); /* IE6-9 */
  }
}

/*------ Ribbon ------*/
.ribbon-featured {
  position: absolute;
  top: -1rem;
  right: 1.5rem;
  z-index: 1;
  .ribbon-content {
    box-shadow: 0 0.1rem rgba(0, 0, 0, 0.15);
    background-color: $color-accent;
    text-transform: uppercase;
    color: $color-white;
    font-weight: 600;
    font-size: 1.1rem;
    z-index: 1;
    padding: 0.7rem;
    &:after {
      background-color: $color-accent;
      width: 0.5rem;
      height: 100%;
      content: '';
      position: absolute;
      top: 0.5rem;
      right: -0.5rem;
    }
    &:before {
      background-color: $color-accent;
      width: 1rem;
      height: 1rem;
      position: absolute;
      top: 0;
      right: -0.5rem;
      content: '';
      border-top-right-radius: 50%;
    }
  }
  .ribbon-start,
  .ribbon-start::after {
    background: $color-accent;
    content: '';
    display: inline-block;
    height: 1rem;
    width: 0.5rem;
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
    right: -0.5rem;
    position: absolute;
    z-index: 1;
    top: 100%;
  }
  .ribbon-start::after {
    background: rgba(0, 0, 0, 0.3);
    z-index: 2;
    right: 0;
    top: 0;
  }
  .ribbon-end {
    height: 100%;
    width: 1.5rem;
    position: absolute;
    top: 0;
    left: -1.5rem;
    &:after,
    &:before,
    .ribbon-shadow::after,
    .ribbon-shadow::before {
      border-style: solid;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
    }
    &:before,
    .ribbon-shadow::before {
      border-width: 0 1.5rem 1.5rem 0;
      border-color: transparent $color-accent transparent transparent;
    }
    &:after,
    .ribbon-shadow::after {
      border-width: 0 0 1.5rem 1.5rem;
      border-color: transparent transparent $color-accent transparent;
      bottom: 0;
    }
    .ribbon-shadow::before {
      border-color: transparent rgba(0, 0, 0, 0.15) transparent transparent;
      top: 0.1rem;
      z-index: -1;
    }
    .ribbon-shadow::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.15) transparent;
      bottom: -0.1rem;
      z-index: -1;
    }
  }
}

/********
--- S ---
********/

.section-title {
  padding-bottom: 4rem;
  h2 {
    margin-bottom: 1rem;
  }
  .chosen-container {
    &:hover,
    &.chosen-container-active {
      .chosen-single {
        background-color: $color-white;
      }
    }
    .chosen-single {
      background-color: transparent;
      box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.05);
    }
  }
  .selectize-input {
    background-color: transparent;
    &:hover,
    &.chosen-container-active {
      background-color: #fff;
    }
  }
}

.sidebar {
  section {
    margin-bottom: 4rem;
  }
  .sidebar-form {
    label {
      display: block;
    }
    .alternative-search-form {
      padding-top: 2rem;
    }
  }

  .sidebar-post {
    margin-bottom: 3rem;
    display: table;
    width: 100%;
    .background-image {
      width: 10rem;
      height: 10rem;
      display: block;
      box-shadow: $shadow-small;
      border-radius: 0.4rem;
      float: left;
    }
    .description {
      margin-left: 12rem;
      h4 {
        margin-bottom: 1.5rem;
      }
      .meta {
        font-size: 1.1rem;
        opacity: 0.6;
        a {
          font-weight: 700;
        }
      }
    }
  }

  .sidebar-list {
    padding-left: 0;
    li {
      border-bottom: 0.1rem solid $color-grey-light;
      a {
        display: block;
        padding: 1rem 0;
        span {
          float: right;
          font-size: 1rem;
          opacity: 0.5;
          line-height: 2rem;
        }
      }
    }
  }
}

.side-nav {
  a {
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
    padding: 1.5rem 0 1.5rem 0;
    &:last-child {
      border: none;
    }
  }
}

.social {
  i {
    font-size: 1.8rem;
    margin-right: 0.5rem;
  }
}

.sources {
  font-style: italic;
  font-weight: 300;
  font-size: 1.1rem;
}

.project__header {
  padding: 1.5rem 0;
}

/********
--- T ---
********/

.tag {
  background-color: $color-black;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  border-radius: 0.2rem;
  padding: 0.3rem 0.5rem;
  font-family: $font-sub;
  white-space: nowrap;
  &:hover {
    background-color: $color-default;
  }
}

.tags {
  &.framed {
    .tag {
      background-color: transparent;
      border: 0.1rem solid $color-grey-light;
    }
  }
}

.tooltip {
  font-size: 1.2rem;
}

.thumbnail-toggle {
  a {
    font-size: 1.5rem;
    margin-left: 0.2rem;
    padding: 0.8rem;
    border-radius: 0.3rem;
    width: 4rem;
    display: inline-block;
    text-align: center;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
    &.active {
      background-color: $color-black;
      color: #fff;
    }
  }
}

/********
--- Animations ---
********/

@keyframes underline-animation {
  0% {
    width: 100%;
  }
  30% {
    width: 0%;
  }
  60% {
    width: 100%;
    background-color: $color-default;
  }
  100% {
    left: 0;
    background-color: $color-default;
  }
}

@keyframes show-form-slide-animation {
  0% {
    opacity: 0;
    transform: translateY(0.5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*! noUiSlider - 7.0.10 - 2014-12-27 14:50:47 */

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
  direction: ltr;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
}
.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
}
.noUi-stacking .noUi-handle {
  z-index: 10;
}
.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
.noUi-base {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.noUi-horizontal {
  height: 18px;
}
.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px;
}
.noUi-vertical {
  width: 18px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}
.noUi-background {
  background: #fafafa;
  box-shadow: inset 0 1px 1px #f0f0f0;
}
.noUi-connect {
  background: #3fb8af;
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
  -webkit-transition: background 450ms;
  transition: background 450ms;
}
.noUi-origin {
  border-radius: 2px;
}
.noUi-target {
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
}
.noUi-target.noUi-connect {
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45), 0 3px 6px -5px #bbb;
}
.noUi-dragable {
  cursor: w-resize;
}
.noUi-vertical .noUi-dragable {
  cursor: n-resize;
}
.noUi-handle {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  cursor: default;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}
.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}
.noUi-handle:after,
.noUi-handle:before {
  content: '';
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #e8e7e6;
  left: 14px;
  top: 6px;
}
.noUi-handle:after {
  left: 17px;
}
.noUi-vertical .noUi-handle:after,
.noUi-vertical .noUi-handle:before {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}
[disabled] .noUi-connect,
[disabled].noUi-connect {
  background: #b8b8b8;
}
[disabled] .noUi-handle {
  cursor: not-allowed;
}

.ui-slider {
  border-radius: 0px;
  box-shadow: none;
  border: none;
  background-color: transparent;
  .noUi-base {
    border-radius: 0px;
    box-shadow: none;
    border: none;
    height: 2px;
    background-color: #e4e4e2;
    margin-top: 6px;
    .noUi-connect {
      box-shadow: none;
      background-color: $color-default;
    }
    .noUi-background {
      box-shadow: none;
      background-color: #e4e4e2;
    }
    .noUi-handle {
      transition: 0.2s;
      border-radius: 50%;
      box-shadow: none;
      border: 2px solid $color-default;
      background-color: $color-default;
      cursor: pointer;
      height: 10px;
      width: 10px;
      left: 0px;
      top: -4px;
      &:before,
      &:after {
        display: none;
      }
      &:hover,
      &.noUi-active {
        box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.07);
      }
      &.noUi-handle-upper {
        left: -8px;
      }
    }
  }
  .values {
    font-size: 10px;
    input {
      background-color: transparent;
      border: none;
      width: 49%;
    }
    input:first-child {
      float: left;
    }
    input:last-child {
      float: right;
      text-align: right;
    }
  }
}
