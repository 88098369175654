/*------------------------------------------------------------------
Project:	Craigs - Easy Buy & Sell Directory Listing Template
Version:	1.0
Last change:	6.9.2017
Assigned to:	ThemeStarz

[Table of contents]

1. Classes
2. Element styling
3. Forms
4. Universal classes
5. Responsive

[Color codes]

default_color[#ff0000];
Text: #363636;

[Typography]

Body copy:		'Poppins', sans-serif; 14px;
Headers:		'Varela Round', sans-serif;

-------------------------------------------------------------------*/
/*1. Classes*/
/********
--- A ---
********/
/*------ Additional Information list ------*/
/* line 6, src/assets/scss/_classes.scss */
.additional-info {
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  padding-top: 1.8rem; }
  /* line 10, src/assets/scss/_classes.scss */
  .additional-info ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0; }
    /* line 15, src/assets/scss/_classes.scss */
    .additional-info ul li figure {
      opacity: 0.6;
      font-size: 1.1rem;
      text-transform: uppercase;
      float: left;
      padding: 0.2rem 0;
      font-weight: 600; }
    /* line 23, src/assets/scss/_classes.scss */
    .additional-info ul li aside {
      font-size: 1.1rem;
      padding: 0.2rem 0;
      text-align: right; }

/* line 32, src/assets/scss/_classes.scss */
.answer {
  margin-bottom: 50px; }
  /* line 34, src/assets/scss/_classes.scss */
  .answer .box {
    padding-top: 40px;
    position: relative; }
    /* line 37, src/assets/scss/_classes.scss */
    .answer .box:after {
      box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08);
      border-radius: 100%;
      content: '?';
      color: #fff;
      font-weight: bold;
      background-color: #2d39b5;
      height: 30px;
      width: 30px;
      line-height: 28px;
      text-align: center;
      position: absolute;
      font-size: 16px;
      top: -15px; }
    /* line 52, src/assets/scss/_classes.scss */
    .answer .box:before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 4px 0 4px;
      border-color: #2d39b5 transparent transparent transparent;
      content: '';
      position: absolute;
      left: 41px;
      top: 11px; }
  /* line 64, src/assets/scss/_classes.scss */
  .answer h3 {
    margin-bottom: 10px; }
  /* line 67, src/assets/scss/_classes.scss */
  .answer figure {
    opacity: 0.5;
    text-align: right;
    font-size: 12px;
    margin-top: 10px; }
    /* line 72, src/assets/scss/_classes.scss */
    .answer figure a {
      margin-left: 10px;
      color: inherit; }
    /* line 76, src/assets/scss/_classes.scss */
    .answer figure i {
      font-size: 10px;
      margin-left: 5px; }

/* line 84, src/assets/scss/_classes.scss */
.alert.alert-warning {
  background-color: #fffde3;
  border: none;
  box-shadow: inset 0 0 0 0.1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  padding: 3rem 4rem; }
  /* line 90, src/assets/scss/_classes.scss */
  .alert.alert-warning h2 {
    margin-bottom: 1rem; }
  /* line 93, src/assets/scss/_classes.scss */
  .alert.alert-warning h4 {
    padding-top: 1rem; }

/* line 103, src/assets/scss/_classes.scss */
.authors.grid .author.item .wrapper, .authors.masonry .author.item .wrapper {
  background-color: #f8f8f8; }
  /* line 106, src/assets/scss/_classes.scss */
  .authors.grid .author.item .wrapper .image .image-wrapper, .authors.masonry .author.item .wrapper .image .image-wrapper {
    border-color: #fff; }

/* line 111, src/assets/scss/_classes.scss */
.authors.grid .author.item .meta, .authors.masonry .author.item .meta {
  background-color: #fff; }

/* line 114, src/assets/scss/_classes.scss */
.authors.grid .author.item .additional-info, .authors.masonry .author.item .additional-info {
  display: block; }

/* line 117, src/assets/scss/_classes.scss */
.authors.grid .author.item h3, .authors.masonry .author.item h3 {
  bottom: 3.5rem; }

/* line 120, src/assets/scss/_classes.scss */
.authors.grid .author.item h4, .authors.masonry .author.item h4 {
  top: 22.5rem; }

/* line 128, src/assets/scss/_classes.scss */
.authors .author.item .meta figure .rating {
  margin-bottom: 0; }
  /* line 130, src/assets/scss/_classes.scss */
  .authors .author.item .meta figure .rating i {
    margin-right: 0;
    font-size: 1.1rem; }

/* line 141, src/assets/scss/_classes.scss */
.author .author-image {
  width: 6rem;
  height: 6rem;
  float: left;
  border-radius: 50%;
  overflow: hidden;
  position: relative; }

/* line 149, src/assets/scss/_classes.scss */
.author .author-description {
  padding-top: 1rem;
  margin-left: 8rem; }

/* line 153, src/assets/scss/_classes.scss */
.author.big {
  padding-bottom: 5rem; }
  /* line 155, src/assets/scss/_classes.scss */
  .author.big .author-image {
    width: 26rem;
    height: 26rem;
    box-shadow: 0 0.1rem 2rem rgba(0, 0, 0, 0.15); }
  /* line 160, src/assets/scss/_classes.scss */
  .author.big .author-description {
    padding-top: 1rem;
    margin-left: 30rem; }
    /* line 163, src/assets/scss/_classes.scss */
    .author.big .author-description h2 {
      font-size: 3rem; }
    /* line 166, src/assets/scss/_classes.scss */
    .author.big .author-description h4 {
      margin-bottom: 1.5rem;
      opacity: 0.5; }
    /* line 171, src/assets/scss/_classes.scss */
    .author.big .author-description .rating i {
      font-size: 1.2rem; }
    /* line 175, src/assets/scss/_classes.scss */
    .author.big .author-description .section-title {
      padding-bottom: 2rem; }
    /* line 178, src/assets/scss/_classes.scss */
    .author.big .author-description .additional-info {
      padding: 2rem;
      background-color: #fff;
      border-radius: 0.3rem;
      margin-bottom: 2rem; }
      /* line 184, src/assets/scss/_classes.scss */
      .author.big .author-description .additional-info ul li {
        display: inline-block;
        margin-right: 2rem; }
        /* line 187, src/assets/scss/_classes.scss */
        .author.big .author-description .additional-info ul li figure {
          float: none;
          padding: 0;
          margin: 0; }
        /* line 192, src/assets/scss/_classes.scss */
        .author.big .author-description .additional-info ul li aside {
          opacity: 1;
          font-size: 1.4rem; }

/********
--- B ---
********/
/* line 207, src/assets/scss/_classes.scss */
.blockquote {
  font-size: 1.8rem; }

/* line 211, src/assets/scss/_classes.scss */
.breadcrumb {
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: 1.1rem;
  text-transform: uppercase; }

/* line 219, src/assets/scss/_classes.scss */
.blog-post {
  border-radius: 0.4rem;
  box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
  margin-bottom: 4rem; }
  /* line 224, src/assets/scss/_classes.scss */
  .blog-post .article-title {
    background-color: #f8f8f8;
    padding: 3rem; }
    /* line 227, src/assets/scss/_classes.scss */
    .blog-post .article-title h2 {
      margin-bottom: 1rem; }
  /* line 231, src/assets/scss/_classes.scss */
  .blog-post img {
    max-width: 100%; }
  /* line 234, src/assets/scss/_classes.scss */
  .blog-post .blog-post-content,
  .blog-post .meta {
    padding: 3rem;
    background-color: #fff; }
  /* line 239, src/assets/scss/_classes.scss */
  .blog-post .meta {
    font-size: 1.1rem;
    padding-bottom: 0; }
    /* line 242, src/assets/scss/_classes.scss */
    .blog-post .meta figure {
      display: inline-block;
      margin-right: 1rem;
      opacity: 0.8; }
  /* line 248, src/assets/scss/_classes.scss */
  .blog-post p {
    margin-bottom: 1rem; }
  /* line 251, src/assets/scss/_classes.scss */
  .blog-post .detail {
    margin-top: 2rem; }
  /* line 255, src/assets/scss/_classes.scss */
  .blog-post .author .section-title {
    padding-bottom: 1rem; }
  /* line 258, src/assets/scss/_classes.scss */
  .blog-post .author .author-image {
    width: 10rem;
    height: 10rem; }
  /* line 262, src/assets/scss/_classes.scss */
  .blog-post .author .author-description {
    margin-left: 13rem; }

/* line 267, src/assets/scss/_classes.scss */
.blog-posts-navigation {
  position: relative; }
  /* line 269, src/assets/scss/_classes.scss */
  .blog-posts-navigation i {
    color: #2d39b5;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 1rem;
    margin: auto; }
  /* line 277, src/assets/scss/_classes.scss */
  .blog-posts-navigation figure {
    opacity: 0.5;
    margin-bottom: 0; }
  /* line 281, src/assets/scss/_classes.scss */
  .blog-posts-navigation .prev,
  .blog-posts-navigation .next {
    position: relative; }
  /* line 285, src/assets/scss/_classes.scss */
  .blog-posts-navigation .prev {
    padding-left: 3rem;
    display: inline-block; }
    /* line 288, src/assets/scss/_classes.scss */
    .blog-posts-navigation .prev i {
      left: 0; }
  /* line 292, src/assets/scss/_classes.scss */
  .blog-posts-navigation .next {
    padding-right: 3rem;
    display: inline-block;
    text-align: right;
    float: right; }
    /* line 297, src/assets/scss/_classes.scss */
    .blog-posts-navigation .next i {
      right: 0; }

/********
--- C ---
********/
/* line 307, src/assets/scss/_classes.scss */
.categories-list {
  list-style: none;
  padding-left: 0; }
  /* line 310, src/assets/scss/_classes.scss */
  .categories-list li {
    position: relative;
    padding-left: 8rem;
    padding-top: 1rem;
    float: left;
    width: 25%;
    margin-bottom: 4rem; }
    /* line 317, src/assets/scss/_classes.scss */
    .categories-list li h3 {
      margin-bottom: 0.5rem; }
    /* line 321, src/assets/scss/_classes.scss */
    .categories-list li .sub-categories a {
      font-size: 1.3rem;
      margin-right: 0.3rem;
      opacity: 0.6;
      transition: 0.3s ease; }
      /* line 326, src/assets/scss/_classes.scss */
      .categories-list li .sub-categories a:hover {
        opacity: 1; }
      /* line 329, src/assets/scss/_classes.scss */
      .categories-list li .sub-categories a:after {
        content: ','; }
      /* line 333, src/assets/scss/_classes.scss */
      .categories-list li .sub-categories a:last-child:after {
        content: '...'; }
    /* line 339, src/assets/scss/_classes.scss */
    .categories-list li i {
      position: absolute;
      width: 6rem;
      height: 6rem;
      text-align: center;
      line-height: 5.8rem;
      background-color: #2d39b5;
      box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08);
      border-radius: 50%;
      left: 0;
      top: 0; }
      /* line 350, src/assets/scss/_classes.scss */
      .categories-list li i img {
        height: 3rem;
        opacity: 0.8;
        filter: invert(100%); }

/* line 360, src/assets/scss/_classes.scss */
.comments .comment {
  margin-bottom: 3rem; }
  /* line 363, src/assets/scss/_classes.scss */
  .comments .comment .author .author-description {
    margin-left: 10rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
    padding-bottom: 2rem;
    margin-bottom: 2rem; }
    /* line 368, src/assets/scss/_classes.scss */
    .comments .comment .author .author-description h3,
    .comments .comment .author .author-description h4 {
      font-size: 1.6rem;
      font-weight: 500;
      font-family: "Varela Round", sans-serif;
      margin-bottom: 1rem; }
    /* line 375, src/assets/scss/_classes.scss */
    .comments .comment .author .author-description h5 {
      font-weight: 600;
      display: inline-block; }
      /* line 378, src/assets/scss/_classes.scss */
      .comments .comment .author .author-description h5 a {
        display: inline-block; }
    /* line 382, src/assets/scss/_classes.scss */
    .comments .comment .author .author-description .meta {
      font-size: 1.1rem;
      margin-bottom: 2rem; }
      /* line 385, src/assets/scss/_classes.scss */
      .comments .comment .author .author-description .meta span {
        margin-right: 1rem; }
      /* line 388, src/assets/scss/_classes.scss */
      .comments .comment .author .author-description .meta span:not(.rating) {
        opacity: 0.5; }
  /* line 393, src/assets/scss/_classes.scss */
  .comments .comment .author .author-image {
    box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08); }

/* line 400, src/assets/scss/_classes.scss */
.comments.masonry .comment .author {
  background-color: #fff;
  box-shadow: 0 0.1rem 2rem rgba(0, 0, 0, 0.15);
  border-radius: 0.4rem;
  padding: 3rem; }

/* line 406, src/assets/scss/_classes.scss */
.comments.masonry .comment .author-description {
  margin-left: 0;
  padding-top: 0;
  border-bottom: none; }
  /* line 410, src/assets/scss/_classes.scss */
  .comments.masonry .comment .author-description h3 {
    position: inherit;
    color: inherit; }
  /* line 414, src/assets/scss/_classes.scss */
  .comments.masonry .comment .author-description .meta {
    background-color: transparent;
    padding: 0; }
    /* line 417, src/assets/scss/_classes.scss */
    .comments.masonry .comment .author-description .meta:before {
      display: none; }

/* line 422, src/assets/scss/_classes.scss */
.comments.masonry .comment h5 {
  line-height: 6rem;
  margin-left: 8rem; }

/* line 430, src/assets/scss/_classes.scss */
.bg-color-accent {
  background-color: #38b52d !important; }

/********
--- E ---
********/
/* line 441, src/assets/scss/_classes.scss */
.elements-grid [class*='col'] div,
.elements-grid [class*='col-'] div {
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-radius: 0.2rem; }

/********
--- F ---
********/
/* line 454, src/assets/scss/_classes.scss */
.feature-box {
  margin-bottom: 3rem; }
  /* line 456, src/assets/scss/_classes.scss */
  .feature-box figure {
    background-color: #2d39b5;
    border-radius: 50%;
    width: 9rem;
    height: 9rem;
    text-align: center;
    line-height: 8.5rem;
    position: relative;
    margin-bottom: 2rem; }
    /* line 465, src/assets/scss/_classes.scss */
    .feature-box figure img {
      height: 3rem;
      opacity: 0.8;
      filter: invert(100%); }
    /* line 470, src/assets/scss/_classes.scss */
    .feature-box figure span {
      display: block;
      position: absolute;
      background-color: #fff;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      text-align: center;
      line-height: 3rem;
      top: 0;
      box-shadow: 0 0.1rem 2rem rgba(0, 0, 0, 0.15);
      right: -0.5rem;
      font-weight: 800; }
    /* line 484, src/assets/scss/_classes.scss */
    .feature-box figure h3 {
      margin-bottom: 1rem; }

/* line 490, src/assets/scss/_classes.scss */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 12rem;
  padding-bottom: 10rem; }
  /* line 496, src/assets/scss/_classes.scss */
  .footer .background {
    background-color: #fff; }
  /* line 499, src/assets/scss/_classes.scss */
  .footer .brand {
    margin-bottom: 4rem;
    display: block; }
  /* line 504, src/assets/scss/_classes.scss */
  .footer nav ul {
    line-height: 3rem;
    opacity: 0.8; }
  /* line 509, src/assets/scss/_classes.scss */
  .footer address {
    opacity: 0.8; }

/********
--- H ---
********/
/* line 517, src/assets/scss/_classes.scss */
.hero {
  z-index: 3;
  background-color: #fff;
  /*background-image: url("../../assets/img/hero-overlay.png"); background-size: contain; background-position: bottom center; background-repeat: no-repeat; padding-bottom: 4rem; position: relative; background-color:#fff; z-index: 3;*/ }
  /* line 520, src/assets/scss/_classes.scss */
  .hero:after {
    background-image: url("../../assets/img/hero-overlay.png");
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
    position: absolute;
    content: '';
    width: 100%;
    height: 4.8rem;
    bottom: 0; }
  /* line 531, src/assets/scss/_classes.scss */
  .hero .hero-wrapper {
    padding-bottom: 7rem; }
  /* line 534, src/assets/scss/_classes.scss */
  .hero .main-navigation {
    z-index: 999;
    position: relative; }
    /* line 537, src/assets/scss/_classes.scss */
    .hero .main-navigation .navbar {
      border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
      padding: 3rem 0;
      margin-bottom: 1rem;
      /*------ Main navigation list ------*/ }
      /* line 542, src/assets/scss/_classes.scss */
      .hero .main-navigation .navbar ul.navbar-nav {
        position: absolute;
        right: 0;
        /*------ Main navigation list item ------*/ }
        /* line 545, src/assets/scss/_classes.scss */
        .hero .main-navigation .navbar ul.navbar-nav a:not(.btn) {
          padding: 0.5rem 1.7rem; }
        /* line 548, src/assets/scss/_classes.scss */
        .hero .main-navigation .navbar ul.navbar-nav .btn {
          margin-left: 1.5rem;
          padding: 0.8rem 1.6rem; }
        /* line 552, src/assets/scss/_classes.scss */
        .hero .main-navigation .navbar ul.navbar-nav li.nav-item {
          position: relative; }
          /* line 556, src/assets/scss/_classes.scss */
          .hero .main-navigation .navbar ul.navbar-nav li.nav-item.has-child > a.nav-link:after {
            font-family: 'fontawesome';
            position: absolute;
            font-size: 1.2rem;
            color: rgba(0, 0, 0, 0.25);
            top: 0;
            bottom: 0;
            margin: auto;
            height: 1.6rem; }
          /* line 569, src/assets/scss/_classes.scss */
          .hero .main-navigation .navbar ul.navbar-nav li.nav-item.has-child:hover > a.nav-link:after {
            color: #2d39b5; }
        /* line 577, src/assets/scss/_classes.scss */
        .hero .main-navigation .navbar ul.navbar-nav > li.nav-item {
          /*------ 1st level list ------*/ }
          /* line 579, src/assets/scss/_classes.scss */
          .hero .main-navigation .navbar ul.navbar-nav > li.nav-item.has-child:hover {
            /*------ 1st level list ------*/ }
            /* line 581, src/assets/scss/_classes.scss */
            .hero .main-navigation .navbar ul.navbar-nav > li.nav-item.has-child:hover > ul.child {
              opacity: 1;
              pointer-events: auto;
              transform: translateY(0rem); }
              /* line 585, src/assets/scss/_classes.scss */
              .hero .main-navigation .navbar ul.navbar-nav > li.nav-item.has-child:hover > ul.child:before {
                pointer-events: auto; }
              /* line 588, src/assets/scss/_classes.scss */
              .hero .main-navigation .navbar ul.navbar-nav > li.nav-item.has-child:hover > ul.child:after {
                border-style: solid;
                border-width: 0 0.45rem 0.6rem 0.45rem;
                border-color: transparent transparent #fff transparent;
                position: absolute;
                top: -0.6rem;
                right: 1.8rem;
                content: ''; }
          /* line 600, src/assets/scss/_classes.scss */
          .hero .main-navigation .navbar ul.navbar-nav > li.nav-item.has-child > a.nav-link:after {
            content: '\f0d7';
            right: 0.4rem; }
          /* line 607, src/assets/scss/_classes.scss */
          .hero .main-navigation .navbar ul.navbar-nav > li.nav-item > ul.child {
            margin-top: 1.5rem; }
            /* line 609, src/assets/scss/_classes.scss */
            .hero .main-navigation .navbar ul.navbar-nav > li.nav-item > ul.child:before {
              position: absolute;
              width: 100%;
              height: 1.5rem;
              background-color: transparent;
              content: '';
              top: -1.5rem;
              right: 0; }
            /* line 619, src/assets/scss/_classes.scss */
            .hero .main-navigation .navbar ul.navbar-nav > li.nav-item > ul.child > li:hover {
              /*------ 2nd level ------*/ }
              /* line 621, src/assets/scss/_classes.scss */
              .hero .main-navigation .navbar ul.navbar-nav > li.nav-item > ul.child > li:hover > ul.child {
                opacity: 1;
                pointer-events: auto;
                transform: translateX(0rem); }
                /* line 626, src/assets/scss/_classes.scss */
                .hero .main-navigation .navbar ul.navbar-nav > li.nav-item > ul.child > li:hover > ul.child > li:hover {
                  /*------ 3rd level ------*/ }
                  /* line 628, src/assets/scss/_classes.scss */
                  .hero .main-navigation .navbar ul.navbar-nav > li.nav-item > ul.child > li:hover > ul.child > li:hover > ul.child {
                    opacity: 1;
                    pointer-events: auto;
                    transform: translateX(0rem); }
                    /* line 633, src/assets/scss/_classes.scss */
                    .hero .main-navigation .navbar ul.navbar-nav > li.nav-item > ul.child > li:hover > ul.child > li:hover > ul.child > li:hover {
                      /*------ 4th level ------*/ }
                      /* line 635, src/assets/scss/_classes.scss */
                      .hero .main-navigation .navbar ul.navbar-nav > li.nav-item > ul.child > li:hover > ul.child > li:hover > ul.child > li:hover > ul.child {
                        opacity: 1;
                        pointer-events: auto;
                        transform: translateX(0rem); }
          /* line 649, src/assets/scss/_classes.scss */
          .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child {
            box-shadow: 0 0.1rem 2rem rgba(0, 0, 0, 0.15);
            border-radius: 0.3rem;
            transform: translateY(0.3rem);
            opacity: 0;
            pointer-events: none;
            transition: 0.3s ease;
            position: absolute;
            right: 0;
            width: 20rem;
            background-color: #fff;
            text-align: right;
            /*------ 1st level and next levels list item ------*/ }
            /* line 661, src/assets/scss/_classes.scss */
            .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child:hover {
              pointer-events: auto; }
            /* line 665, src/assets/scss/_classes.scss */
            .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li {
              border-bottom: 0.1rem solid rgba(0, 0, 0, 0.04);
              transition: 0.3s ease;
              /*------ 2nd and next levels ------*/ }
              /* line 668, src/assets/scss/_classes.scss */
              .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li:hover {
                background-color: rgba(0, 0, 0, 0.02); }
              /* line 673, src/assets/scss/_classes.scss */
              .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li.has-child > a.nav-link:after {
                content: '\f0d9';
                left: 1rem; }
              /* line 679, src/assets/scss/_classes.scss */
              .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li a.nav-link {
                padding: 1rem 1.5rem; }
              /* line 683, src/assets/scss/_classes.scss */
              .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li ul {
                right: 20rem;
                top: 0;
                transform: translateX(0.3rem);
                margin-right: 0.5rem; }
                /* line 688, src/assets/scss/_classes.scss */
                .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li ul li {
                  /*----- Small right arrow on first list item ------*/ }
                  /* line 691, src/assets/scss/_classes.scss */
                  .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li ul li:first-child:after {
                    background-color: transparent;
                    width: 0.5rem;
                    height: 100%;
                    content: '';
                    position: absolute;
                    right: -0.5rem;
                    top: 0; }
                  /* line 700, src/assets/scss/_classes.scss */
                  .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li ul li:first-child:before {
                    border-style: solid;
                    border-width: 0.45rem 0 0.45rem 0.6rem;
                    border-color: transparent transparent transparent #fff;
                    content: '';
                    position: absolute;
                    top: 1.6rem;
                    right: -0.6rem;
                    z-index: 1; }
                  /* line 711, src/assets/scss/_classes.scss */
                  .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li ul li:first-child:hover:before {
                    border-color: transparent transparent transparent #fafafa; }
    /* line 723, src/assets/scss/_classes.scss */
    .hero .main-navigation .main-search-form-toggle {
      box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
      position: absolute;
      right: 0;
      bottom: -4.1rem;
      background-color: #2d39b5;
      padding: 1rem 1.4rem;
      color: #fff;
      border-bottom-left-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem; }
      /* line 733, src/assets/scss/_classes.scss */
      .hero .main-navigation .main-search-form-toggle:hover {
        box-shadow: 0 0.4rem 3.3rem rgba(0, 0, 0, 0.3); }
      /* line 736, src/assets/scss/_classes.scss */
      .hero .main-navigation .main-search-form-toggle i {
        transition: 0.3s ease; }
        /* line 738, src/assets/scss/_classes.scss */
        .hero .main-navigation .main-search-form-toggle i.fa-close {
          opacity: 0;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 1.7rem;
          right: 0;
          left: 0;
          width: 1rem; }
      /* line 751, src/assets/scss/_classes.scss */
      .hero .main-navigation .main-search-form-toggle[aria-expanded='true'] .fa-close {
        opacity: 1; }
      /* line 754, src/assets/scss/_classes.scss */
      .hero .main-navigation .main-search-form-toggle[aria-expanded='true'] .fa-search {
        opacity: 0; }
  /* line 761, src/assets/scss/_classes.scss */
  .hero .secondary-navigation {
    background-color: #363636;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 500;
    display: table;
    width: 100%;
    height: 24px; }
    /* line 769, src/assets/scss/_classes.scss */
    .hero .secondary-navigation a,
    .hero .secondary-navigation span {
      color: #fff;
      padding: 0.4rem;
      display: inline-block;
      text-decoration: none; }
    /* line 777, src/assets/scss/_classes.scss */
    .hero .secondary-navigation a:hover {
      background-color: rgba(255, 255, 255, 0.1); }
    /* line 781, src/assets/scss/_classes.scss */
    .hero .secondary-navigation i {
      opacity: 0.5;
      margin-right: 0.5rem; }
    /* line 785, src/assets/scss/_classes.scss */
    .hero .secondary-navigation .left {
      float: left; }
      /* line 788, src/assets/scss/_classes.scss */
      .hero .secondary-navigation .left li:first-child {
        padding-left: 0; }
    /* line 793, src/assets/scss/_classes.scss */
    .hero .secondary-navigation .right {
      float: right; }
      /* line 795, src/assets/scss/_classes.scss */
      .hero .secondary-navigation .right li {
        border-left: 0.1rem solid rgba(255, 255, 255, 0.2); }
        /* line 797, src/assets/scss/_classes.scss */
        .hero .secondary-navigation .right li:last-child {
          border-right: 0.1rem solid rgba(255, 255, 255, 0.2); }
    /* line 803, src/assets/scss/_classes.scss */
    .hero .secondary-navigation ul li {
      float: left; }
  /* line 810, src/assets/scss/_classes.scss */
  .hero.has-map .hero-form {
    padding-top: 8rem;
    padding-bottom: 6rem;
    box-shadow: 0rem -0.5rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: relative; }
  /* line 817, src/assets/scss/_classes.scss */
  .hero.has-map .hero-wrapper {
    padding-bottom: 0; }
  /* line 820, src/assets/scss/_classes.scss */
  .hero.has-map .main-navigation {
    box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08); }
    /* line 822, src/assets/scss/_classes.scss */
    .hero.has-map .main-navigation .navbar {
      margin-bottom: 0;
      border-bottom: none; }
  /* line 829, src/assets/scss/_classes.scss */
  .hero ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  /* line 835, src/assets/scss/_classes.scss */
  .hero .page-title {
    padding-top: 5rem;
    padding-bottom: 3rem; }
    /* line 838, src/assets/scss/_classes.scss */
    .hero .page-title a.title-link {
      position: relative; }
      /* line 840, src/assets/scss/_classes.scss */
      .hero .page-title a.title-link:hover::after {
        animation-name: underline-animation;
        animation-duration: 0.7s;
        animation-fill-mode: forwards;
        /*background-color: $color-default; transition: $transition;*/ }
      /* line 845, src/assets/scss/_classes.scss */
      .hero .page-title a.title-link:after {
        background-color: #000;
        width: 100%;
        height: 0.2rem;
        content: '';
        position: absolute;
        bottom: 0;
        right: 0; }
    /* line 856, src/assets/scss/_classes.scss */
    .hero .page-title h1 .tag {
      vertical-align: middle;
      position: relative;
      top: -0.3rem;
      margin-left: 1rem;
      background-color: #2d39b5;
      color: #fff; }
    /* line 865, src/assets/scss/_classes.scss */
    .hero .page-title h4 {
      opacity: 0.5; }
      /* line 868, src/assets/scss/_classes.scss */
      .hero .page-title h4.location:before {
        font-family: 'fontawesome';
        color: #000; }
      /* line 874, src/assets/scss/_classes.scss */
      .hero .page-title h4 a:after {
        display: none; }
    /* line 879, src/assets/scss/_classes.scss */
    .hero .page-title .price {
      text-align: right; }
      /* line 881, src/assets/scss/_classes.scss */
      .hero .page-title .price .number {
        font-family: "Varela Round", sans-serif;
        color: #38b52d;
        font-size: 3.6rem;
        line-height: 1.1; }

/********
--- I ---
********/
/*------ Items ------*/
/* line 896, src/assets/scss/_classes.scss */
.items:not(.selectize-input) {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  /*------ Item ------*/
  /*------------------*/
  /*- Grid, Masonry --*/
  /*------------------*/
  /*------------------*/
  /*----- Grid -------*/
  /*------------------*/
  /*------------------*/
  /*------ List ------*/
  /*------------------*/ }
  /* line 899, src/assets/scss/_classes.scss */
  .items:not(.selectize-input):after {
    clear: both;
    content: '';
    display: block; }
  /* line 906, src/assets/scss/_classes.scss */
  .items:not(.selectize-input) .item {
    margin-bottom: 3rem;
    position: relative;
    /*------ Call to action item ------*/
    /*------ Image ------*/
    /*------ Tags ------*/
    /*------ H3 heading ------*/
    /*------ H4 heading ------*/
    /*------ Price ------*/
    /*------ Meta ------*/
    /*------ Description ------*/
    /*------ Detail link ------*/ }
    /* line 909, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item .wrapper {
      overflow: hidden;
      box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
      transition: 0.3s box-shadow ease, 0.3s transform ease;
      border-radius: 0.5rem;
      background-color: #fff;
      position: relative;
      transform: translateY(0);
      /*padding-bottom: 5rem;*/ }
      /* line 917, src/assets/scss/_classes.scss */
      .items:not(.selectize-input) .item .wrapper:hover {
        box-shadow: 0 0.4rem 3.3rem rgba(0, 0, 0, 0.3);
        transform: translateY(-0.2rem); }
    /* line 923, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item.call-to-action {
      text-align: center;
      height: 30rem;
      display: block; }
      /* line 927, src/assets/scss/_classes.scss */
      .items:not(.selectize-input) .item.call-to-action .wrapper {
        background-color: transparent;
        box-shadow: none;
        display: table;
        width: 100%;
        height: 100%;
        border: 0.1rem solid rgba(0, 0, 0, 0.05);
        transition: 0.3s box-shadow ease, 0.3s background-color ease, 0.3s transform ease;
        padding-bottom: 0; }
        /* line 936, src/assets/scss/_classes.scss */
        .items:not(.selectize-input) .item.call-to-action .wrapper:hover {
          background-color: rgba(0, 0, 0, 0.03); }
        /* line 939, src/assets/scss/_classes.scss */
        .items:not(.selectize-input) .item.call-to-action .wrapper .title {
          color: #000;
          font-size: 1.3rem;
          display: table-cell;
          vertical-align: middle;
          font-family: "Varela Round", sans-serif; }
          /* line 945, src/assets/scss/_classes.scss */
          .items:not(.selectize-input) .item.call-to-action .wrapper .title i {
            display: block;
            font-size: 2.4rem;
            color: #2d39b5;
            margin-bottom: 1rem; }
          /* line 951, src/assets/scss/_classes.scss */
          .items:not(.selectize-input) .item.call-to-action .wrapper .title .cta {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1rem;
            width: 10rem; }
    /* line 963, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item .image {
      height: 26rem;
      position: relative;
      border-top-left-radius: 0.6rem;
      border-top-right-radius: 0.6rem;
      background-color: #f8f8f8; }
      /* line 969, src/assets/scss/_classes.scss */
      .items:not(.selectize-input) .item .image .image-wrapper {
        transition: none;
        display: block;
        position: relative;
        z-index: 0; }
        /* line 974, src/assets/scss/_classes.scss */
        .items:not(.selectize-input) .item .image .image-wrapper:before {
          opacity: 0.8;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 8rem;
          content: '';
          background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
          background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=0 ); }
    /* line 991, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item .tag, .items:not(.selectize-input) .item h3, .items:not(.selectize-input) .item h4, .items:not(.selectize-input) .item .price {
      position: absolute; }
    /* line 994, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item .tag {
      color: #fff;
      position: absolute;
      z-index: 1;
      left: 1.8rem;
      top: 12rem;
      transition: 0.3s background-color ease;
      padding: 0.4rem 0.5rem; }
    /* line 1005, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item h3 {
      position: absolute;
      z-index: 1;
      left: 1.8rem;
      color: white;
      bottom: 6rem;
      padding-right: 1.8rem;
      margin-bottom: 0.5rem; }
      /* line 1013, src/assets/scss/_classes.scss */
      .items:not(.selectize-input) .item h3 a {
        color: #fff;
        transition: none;
        text-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.6);
        display: block; }
        /* line 1018, src/assets/scss/_classes.scss */
        .items:not(.selectize-input) .item h3 a:hover {
          text-decoration: underline; }
        /* line 1021, src/assets/scss/_classes.scss */
        .items:not(.selectize-input) .item h3 a.category {
          display: inline-block;
          color: #fff;
          position: relative;
          transition: 0.3s background-color ease;
          font-weight: 600;
          font-size: 1rem;
          text-shadow: none;
          text-decoration: none;
          top: inherit;
          bottom: 1rem;
          left: 0; }
      /* line 1035, src/assets/scss/_classes.scss */
      .items:not(.selectize-input) .item h3 .tag:not(.category) {
        background-color: #fff;
        top: inherit;
        bottom: 16rem;
        left: 0;
        color: #000;
        font-weight: 600;
        font-size: 1rem;
        opacity: 0.6;
        transition: none;
        pointer-events: none; }
    /* line 1050, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item h4 {
      position: absolute;
      z-index: 1;
      left: 1.8rem;
      color: white;
      top: 20rem;
      font-weight: 300;
      font-size: 1.4rem;
      opacity: 0.8; }
      /* line 1059, src/assets/scss/_classes.scss */
      .items:not(.selectize-input) .item h4 a {
        color: #fff;
        transition: none; }
        /* line 1062, src/assets/scss/_classes.scss */
        .items:not(.selectize-input) .item h4 a:hover {
          text-decoration: underline; }
      /* line 1067, src/assets/scss/_classes.scss */
      .items:not(.selectize-input) .item h4.location:before {
        color: #fff; }
    /* line 1074, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item .price {
      background-color: #f8f8f8;
      font-size: 1.6rem;
      font-weight: 600;
      position: absolute;
      top: 22.6rem;
      left: 1.8rem;
      padding: 0.5rem 1.8rem;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
      z-index: 1; }
      /* line 1085, src/assets/scss/_classes.scss */
      .items:not(.selectize-input) .item .price .appendix {
        font-size: 1rem;
        margin-right: 0.6rem;
        opacity: 0.7;
        top: -0.2rem;
        position: relative;
        display: block;
        margin-bottom: -0.7rem; }
    /* line 1097, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item .meta {
      background-color: #f8f8f8;
      padding: 1.8rem;
      font-size: 1.2rem;
      width: 100%;
      white-space: nowrap;
      margin-top: -0.2rem;
      position: relative; }
      /* line 1105, src/assets/scss/_classes.scss */
      .items:not(.selectize-input) .item .meta:before {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f8f8f8+0,f8f8f8+100&0+0,1+50 */
        background: -moz-linear-gradient(left, rgba(248, 248, 248, 0) 0%, #f8f8f8 50%, #f8f8f8 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(248, 248, 248, 0) 0%, #f8f8f8 50%, #f8f8f8 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(248, 248, 248, 0) 0%, #f8f8f8 50%, #f8f8f8 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f8f8f8', endColorstr='#f8f8f8',GradientType=1 );
        /* IE6-9 */
        height: 100%;
        width: 4rem;
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1; }
      /* line 1125, src/assets/scss/_classes.scss */
      .items:not(.selectize-input) .item .meta figure {
        opacity: 0.6;
        margin-right: 2rem;
        display: inline-block; }
        /* line 1129, src/assets/scss/_classes.scss */
        .items:not(.selectize-input) .item .meta figure i {
          margin-right: 1rem; }
        /* line 1132, src/assets/scss/_classes.scss */
        .items:not(.selectize-input) .item .meta figure a {
          transition: 0.3s color ease; }
    /* line 1139, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item .description {
      font-size: 1.3rem;
      padding: 1.8rem 1.8rem 0 1.8rem; }
      /* line 1142, src/assets/scss/_classes.scss */
      .items:not(.selectize-input) .item .description p {
        margin-bottom: 0; }
    /* line 1148, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item .detail {
      bottom: 1.8rem;
      left: 1.8rem;
      color: #2d39b5;
      transition: 0.3s background-color ease;
      position: absolute; }
      /* line 1154, src/assets/scss/_classes.scss */
      .items:not(.selectize-input) .item .detail:after {
        background-color: #2d39b5; }
    /* line 1159, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item figure {
      margin-bottom: 0; }
    /* line 1164, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item.author .meta:before {
      display: none; }
    /* line 1167, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item.author .meta:after {
      display: block;
      clear: both;
      content: ''; }
    /* line 1172, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item.author .meta figure {
      margin-right: 0;
      float: left; }
      /* line 1175, src/assets/scss/_classes.scss */
      .items:not(.selectize-input) .item.author .meta figure:last-child {
        float: right; }
  /* line 1186, src/assets/scss/_classes.scss */
  .items:not(.selectize-input).grid, .items:not(.selectize-input).masonry {
    /*------ Border around image ------*/ }
    /* line 1191, src/assets/scss/_classes.scss */
    .items:not(.selectize-input).grid .item .image .image-wrapper, .items:not(.selectize-input).masonry .item .image .image-wrapper {
      border: 0.6rem solid #f8f8f8;
      border-radius: 0.9rem; }
    /* line 1197, src/assets/scss/_classes.scss */
    .items:not(.selectize-input).grid .item, .items:not(.selectize-input).masonry .item {
      float: left; }
      /* line 1199, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).grid .item .wrapper, .items:not(.selectize-input).masonry .item .wrapper {
        float: left; }
      /* line 1202, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).grid .item p, .items:not(.selectize-input).masonry .item p {
        height: 6rem;
        overflow: hidden; }
  /* line 1211, src/assets/scss/_classes.scss */
  .items:not(.selectize-input).masonry .item .wrapper {
    padding-bottom: 5rem; }
  /* line 1220, src/assets/scss/_classes.scss */
  .items:not(.selectize-input).grid .item {
    height: 43rem; }
    /* line 1222, src/assets/scss/_classes.scss */
    .items:not(.selectize-input).grid .item .additional-info {
      display: none; }
  /* line 1227, src/assets/scss/_classes.scss */
  .items:not(.selectize-input).grid.compact .item {
    height: inherit; }
    /* line 1229, src/assets/scss/_classes.scss */
    .items:not(.selectize-input).grid.compact .item .wrapper {
      height: 31rem; }
      /* line 1231, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).grid.compact .item .wrapper .detail {
        display: none; }
    /* line 1235, src/assets/scss/_classes.scss */
    .items:not(.selectize-input).grid.compact .item .admin-controls {
      position: relative;
      right: inherit;
      text-align: center;
      height: auto;
      border-left: none;
      padding-left: 0;
      padding-right: 0;
      padding-top: 1.2rem; }
      /* line 1244, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).grid.compact .item .admin-controls a {
        display: inline-block;
        padding: 0.5rem; }
        /* line 1247, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).grid.compact .item .admin-controls a i {
          margin-right: 0.5rem; }
  /* line 1259, src/assets/scss/_classes.scss */
  .items:not(.selectize-input).list {
    height: inherit !important;
    /*------  Border around image ------*/
    /*------ Item ------*/
    /*-------------------------*/
    /*-- Compact Item - List --*/
    /*-------------------------*/ }
    /* line 1265, src/assets/scss/_classes.scss */
    .items:not(.selectize-input).list .item .image .image-wrapper {
      border: 0.6rem solid #fff;
      border-radius: 0.9rem; }
    /* line 1273, src/assets/scss/_classes.scss */
    .items:not(.selectize-input).list .item {
      position: relative !important;
      top: inherit !important;
      left: inherit !important;
      /*------ H3 ------*/
      /*------ H4 ------*/
      /*------ Price ------*/
      /*------ Tag ------*/
      /*------ Meta ------*/
      /*------ Description ------*/
      /*------ Detail ------*/
      /*------ Additional Info ------*/ }
      /* line 1277, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list .item .wrapper {
        min-height: 18rem;
        padding-bottom: 0; }
      /* line 1281, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list .item .image {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: transparent; }
        /* line 1286, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list .item .image .background-image {
          width: 25rem; }
      /* line 1292, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list .item h3 {
        top: 2.5rem;
        font-size: 2.2rem;
        color: #000;
        left: 27rem;
        bottom: inherit;
        width: 100%; }
        /* line 1299, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list .item h3 a:not(.category) {
          color: #000;
          text-shadow: none;
          display: inline-block; }
          /* line 1303, src/assets/scss/_classes.scss */
          .items:not(.selectize-input).list .item h3 a:not(.category):hover {
            text-decoration: none;
            color: #2d39b5; }
        /* line 1308, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list .item h3 .tag:not(.category) {
          position: relative;
          top: -0.2rem;
          background-color: transparent;
          border: 0.1rem solid rgba(0, 0, 0, 0.3);
          vertical-align: middle; }
        /* line 1315, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list .item h3 .tag.category {
          position: absolute;
          color: #fff;
          bottom: inherit;
          top: -1rem;
          left: -25.5rem; }
      /* line 1325, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list .item h4 {
        top: 5.5rem;
        left: 27rem; }
        /* line 1328, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list .item h4 a {
          color: #000; }
        /* line 1332, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list .item h4.location:before {
          font-family: 'fontawesome';
          color: #000; }
      /* line 1340, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list .item .price {
        top: inherit;
        border-radius: 0.3rem;
        bottom: 2rem; }
      /* line 1347, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list .item .tag {
        top: 2rem; }
      /* line 1352, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list .item .meta {
        position: absolute;
        padding: 3rem 2rem 0 0;
        right: 0;
        width: auto;
        font-size: 1.1rem;
        background-color: transparent;
        text-align: right;
        top: 0;
        z-index: 1; }
        /* line 1362, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list .item .meta:before {
          display: none; }
        /* line 1365, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list .item .meta figure {
          display: block;
          margin-right: 0; }
      /* line 1372, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list .item .description {
        position: absolute;
        bottom: 2rem;
        padding: 0;
        left: 27rem;
        z-index: 1;
        height: 4rem;
        overflow: hidden; }
        /* line 1380, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list .item .description p {
          width: 80%; }
      /* line 1386, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list .item .detail {
        right: 2rem;
        bottom: 2rem;
        left: inherit;
        border: 0.1rem solid #2d39b5;
        padding: 1rem;
        text-transform: none;
        font-size: 1.4rem;
        border-radius: 0.3rem;
        z-index: 2; }
        /* line 1396, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list .item .detail:hover {
          background-color: #2d39b5;
          color: #fff; }
        /* line 1400, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list .item .detail:after {
          display: none; }
      /* line 1406, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list .item .additional-info {
        padding: 9rem 0 8rem 0;
        margin-right: 2rem;
        position: relative;
        margin-left: 27rem; }
        /* line 1411, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list .item .additional-info ul {
          background-color: #f9f9f9;
          border-radius: 0.3rem;
          margin-bottom: 0;
          padding: 1.5rem; }
          /* line 1416, src/assets/scss/_classes.scss */
          .items:not(.selectize-input).list .item .additional-info ul li {
            display: inline-block;
            margin-right: 5rem; }
            /* line 1419, src/assets/scss/_classes.scss */
            .items:not(.selectize-input).list .item .additional-info ul li figure {
              float: none; }
            /* line 1422, src/assets/scss/_classes.scss */
            .items:not(.selectize-input).list .item .additional-info ul li aside {
              text-align: left; }
      /* line 1428, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list .item.call-to-action {
        height: 15rem; }
    /* line 1437, src/assets/scss/_classes.scss */
    .items:not(.selectize-input).list.compact .item {
      min-height: 17rem; }
      /* line 1439, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list.compact .item .image {
        padding-right: 20rem; }
        /* line 1441, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list.compact .item .image .background-image {
          width: 20rem; }
        /* line 1445, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list.compact .item .image .image-wrapper:before {
          opacity: 0.6;
          height: 8rem; }
      /* line 1451, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list.compact .item .additional-info {
        margin-left: 22rem;
        padding: 8rem 0 6.3rem 0; }
        /* line 1454, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list.compact .item .additional-info ul {
          padding: 0.4rem 0.4rem 0 0.4rem;
          /*background-color: transparent;*/ }
          /* line 1457, src/assets/scss/_classes.scss */
          .items:not(.selectize-input).list.compact .item .additional-info ul li figure {
            padding: 0;
            margin-bottom: -0.4rem;
            font-size: 1rem; }
      /* line 1465, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list.compact .item h3,
      .items:not(.selectize-input).list.compact .item h4,
      .items:not(.selectize-input).list.compact .item .description {
        left: 22rem; }
      /* line 1471, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list.compact .item h3 .tag.category {
        left: -20.3rem; }
      /* line 1475, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list.compact .item .price {
        padding: 0.3rem 1.1rem;
        font-size: 1.3rem; }
      /* line 1479, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list.compact .item .description {
        padding-right: 20rem;
        bottom: 1.4rem; }
        /* line 1482, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list.compact .item .description p {
          font-size: 1.2rem; }
      /* line 1486, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list.compact .item .detail {
        border: inherit;
        text-transform: uppercase;
        font-size: 1.1rem;
        bottom: 2rem;
        padding: 0; }
        /* line 1492, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list.compact .item .detail:hover {
          background-color: transparent;
          color: #2d39b5; }
        /* line 1496, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list.compact .item .detail:after {
          display: block; }
      /* line 1500, src/assets/scss/_classes.scss */
      .items:not(.selectize-input).list.compact .item .admin-controls {
        position: absolute;
        right: 0;
        height: 8rem;
        top: 0;
        bottom: 0;
        margin: auto;
        border-left: 0.1rem solid rgba(0, 0, 0, 0.1);
        padding-left: 2rem;
        padding-right: 2rem;
        z-index: 1;
        background-color: #fff; }
        /* line 1512, src/assets/scss/_classes.scss */
        .items:not(.selectize-input).list.compact .item .admin-controls a {
          display: block;
          padding: 0.4rem 0; }
          /* line 1515, src/assets/scss/_classes.scss */
          .items:not(.selectize-input).list.compact .item .admin-controls a i {
            margin-right: 1rem; }
  /* line 1523, src/assets/scss/_classes.scss */
  .items:not(.selectize-input) .item {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
    /* line 1526, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item .wrapper {
      width: 100%;
      height: 100%; }
    /* line 1530, src/assets/scss/_classes.scss */
    .items:not(.selectize-input) .item .admin-controls {
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 600; }
      /* line 1534, src/assets/scss/_classes.scss */
      .items:not(.selectize-input) .item .admin-controls a {
        display: inline-block;
        padding: 0.5rem; }
        /* line 1537, src/assets/scss/_classes.scss */
        .items:not(.selectize-input) .item .admin-controls a i {
          margin-right: 0.5rem;
          opacity: 0.5; }

/* line 1546, src/assets/scss/_classes.scss */
.logo {
  width: 135px; }

/* line 1551, src/assets/scss/_classes.scss */
.map#map-small {
  box-shadow: 0 0.1rem 2rem rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  display: block; }

/* line 1556, src/assets/scss/_classes.scss */
.map#map-contact {
  margin-top: -5rem;
  margin-bottom: 5rem; }
  /* line 1559, src/assets/scss/_classes.scss */
  .map#map-contact:before {
    background-image: url("../../assets/img/footer-overlay.png");
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    padding-bottom: 10rem;
    position: absolute;
    content: '';
    width: 100%;
    height: auto;
    z-index: 1;
    pointer-events: none; }

/* line 1574, src/assets/scss/_classes.scss */
.map .cluster > div {
  color: #fff !important; }

/* line 1578, src/assets/scss/_classes.scss */
.map .marker {
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  transform: translateY(0.2rem); }
  /* line 1583, src/assets/scss/_classes.scss */
  .map .marker:hover {
    opacity: 1;
    transform: translateY(0); }

/* line 1589, src/assets/scss/_classes.scss */
.map .infobox-wrapper {
  position: relative;
  display: inline-block; }
  /* line 1592, src/assets/scss/_classes.scss */
  .map .infobox-wrapper > img {
    position: absolute !important;
    top: -1.5rem;
    right: -1.5rem;
    z-index: 1; }
  /* line 1598, src/assets/scss/_classes.scss */
  .map .infobox-wrapper:after {
    background-image: url("../../assets/img/infobox-arrow.png");
    content: '';
    display: block;
    width: 100%;
    height: 1.6rem;
    background-position: center;
    background-repeat: no-repeat; }
  /* line 1607, src/assets/scss/_classes.scss */
  .map .infobox-wrapper .ribbon-featured {
    right: 0;
    background-color: #2d39b5;
    padding: 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    border-radius: 0.4rem;
    box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08);
    top: 2.5rem; }
  /* line 1619, src/assets/scss/_classes.scss */
  .map .infobox-wrapper a:hover {
    color: inherit; }
  /* line 1623, src/assets/scss/_classes.scss */
  .map .infobox-wrapper .infobox {
    width: 25rem;
    background-color: #f8f8f8;
    padding: 0.5rem;
    border-radius: 0.6rem;
    overflow: hidden;
    box-shadow: 0 0.1rem 2rem rgba(0, 0, 0, 0.15); }
    /* line 1630, src/assets/scss/_classes.scss */
    .map .infobox-wrapper .infobox .image-wrapper {
      width: 100%;
      height: 20rem;
      overflow: hidden;
      border-radius: 0.4rem;
      position: relative; }
      /* line 1636, src/assets/scss/_classes.scss */
      .map .infobox-wrapper .infobox .image-wrapper > .tag,
      .map .infobox-wrapper .infobox .image-wrapper h3,
      .map .infobox-wrapper .infobox .image-wrapper .price {
        position: absolute;
        left: 2rem;
        z-index: 1; }
      /* line 1643, src/assets/scss/_classes.scss */
      .map .infobox-wrapper .infobox .image-wrapper .price {
        bottom: -0.3rem;
        background-color: #f8f8f8;
        font-size: 1.6rem;
        font-weight: 600;
        padding: 0.5rem 1.8rem;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        z-index: 1; }
        /* line 1652, src/assets/scss/_classes.scss */
        .map .infobox-wrapper .infobox .image-wrapper .price .appendix {
          font-size: 1rem;
          margin-right: 0;
          opacity: 0.7;
          top: -0.2rem;
          position: relative;
          display: block;
          margin-bottom: -0.7rem;
          margin-left: 0; }
      /* line 1663, src/assets/scss/_classes.scss */
      .map .infobox-wrapper .infobox .image-wrapper .type {
        background-color: #fff;
        opacity: 0.8;
        top: 2rem; }
      /* line 1668, src/assets/scss/_classes.scss */
      .map .infobox-wrapper .infobox .image-wrapper h3 {
        bottom: 2rem;
        color: #fff; }
        /* line 1671, src/assets/scss/_classes.scss */
        .map .infobox-wrapper .infobox .image-wrapper h3 span:not(.tag) {
          display: block;
          margin-top: 0.8rem; }
      /* line 1676, src/assets/scss/_classes.scss */
      .map .infobox-wrapper .infobox .image-wrapper .image {
        height: 100%;
        width: 100%;
        background-size: cover;
        position: relative; }
        /* line 1681, src/assets/scss/_classes.scss */
        .map .infobox-wrapper .infobox .image-wrapper .image:before {
          opacity: 0.8;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 15rem;
          content: '';
          background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
          background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=0 ); }
      /* line 1695, src/assets/scss/_classes.scss */
      .map .infobox-wrapper .infobox .image-wrapper img {
        width: 100%; }

/********
--- N ---
********/
/* line 1709, src/assets/scss/_classes.scss */
.nav-tabs .nav-link,
.nav-pills .nav-link {
  padding: 1rem 2rem; }

/* line 1715, src/assets/scss/_classes.scss */
.nav-pills .nav-link.active {
  background-color: #2d39b5; }

/* line 1720, src/assets/scss/_classes.scss */
.tab-content {
  padding-top: 2rem; }

/********
--- O ---
********/
/* line 1729, src/assets/scss/_classes.scss */
.owl-carousel.full-width-carousel {
  position: relative;
  top: -5.5rem;
  margin-bottom: -5.5rem; }
  /* line 1733, src/assets/scss/_classes.scss */
  .owl-carousel.full-width-carousel:before {
    background-image: url("../../assets/img/footer-overlay.png");
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    padding-bottom: 10rem;
    position: absolute;
    content: '';
    width: 100%;
    height: auto;
    z-index: 1; }
  /* line 1745, src/assets/scss/_classes.scss */
  .owl-carousel.full-width-carousel .item {
    width: 111rem;
    border-radius: 0.4rem;
    height: 70rem;
    overflow: hidden; }
  /* line 1751, src/assets/scss/_classes.scss */
  .owl-carousel.full-width-carousel .owl-item {
    opacity: 0.2;
    transition: 1s opacity ease; }
    /* line 1754, src/assets/scss/_classes.scss */
    .owl-carousel.full-width-carousel .owl-item.active.center {
      opacity: 1; }

/* line 1759, src/assets/scss/_classes.scss */
.owl-carousel.gallery-carousel {
  margin-bottom: 3rem; }

/* line 1764, src/assets/scss/_classes.scss */
.owl-carousel.gallery-carousel .owl-stage-outer, .owl-carousel.full-width-carousel .owl-stage-outer {
  border-radius: 0.4rem; }

/* line 1768, src/assets/scss/_classes.scss */
.owl-carousel.gallery-carousel .owl-nav .owl-prev,
.owl-carousel.gallery-carousel .owl-nav .owl-next, .owl-carousel.full-width-carousel .owl-nav .owl-prev,
.owl-carousel.full-width-carousel .owl-nav .owl-next {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 5rem; }
  /* line 1776, src/assets/scss/_classes.scss */
  .owl-carousel.gallery-carousel .owl-nav .owl-prev i,
  .owl-carousel.gallery-carousel .owl-nav .owl-next i, .owl-carousel.full-width-carousel .owl-nav .owl-prev i,
  .owl-carousel.full-width-carousel .owl-nav .owl-next i {
    background-color: #2d39b5;
    width: 5rem;
    text-align: center;
    line-height: 5rem;
    color: #fff;
    box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08);
    border-radius: 50%; }

/* line 1786, src/assets/scss/_classes.scss */
.owl-carousel.gallery-carousel .owl-nav .owl-prev, .owl-carousel.full-width-carousel .owl-nav .owl-prev {
  left: 0; }
  /* line 1788, src/assets/scss/_classes.scss */
  .owl-carousel.gallery-carousel .owl-nav .owl-prev i, .owl-carousel.full-width-carousel .owl-nav .owl-prev i {
    margin-left: 2rem; }

/* line 1792, src/assets/scss/_classes.scss */
.owl-carousel.gallery-carousel .owl-nav .owl-next, .owl-carousel.full-width-carousel .owl-nav .owl-next {
  right: 0;
  margin-right: 2rem; }

/* line 1799, src/assets/scss/_classes.scss */
.owl-carousel.gallery-carousel-thumbs .owl-thumb {
  border-radius: 0.4rem;
  height: 13rem;
  display: block;
  opacity: 0.3;
  transition: 0.3s ease; }
  /* line 1805, src/assets/scss/_classes.scss */
  .owl-carousel.gallery-carousel-thumbs .owl-thumb:hover {
    opacity: 1; }
  /* line 1808, src/assets/scss/_classes.scss */
  .owl-carousel.gallery-carousel-thumbs .owl-thumb img {
    width: 100%;
    display: none; }
  /* line 1812, src/assets/scss/_classes.scss */
  .owl-carousel.gallery-carousel-thumbs .owl-thumb.active-thumb {
    box-shadow: inset 0 0 0 0.3rem #2d39b5;
    opacity: 1; }

/* line 1818, src/assets/scss/_classes.scss */
.owl-carousel .owl-dots {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center; }
  /* line 1822, src/assets/scss/_classes.scss */
  .owl-carousel .owl-dots .owl-dot {
    display: inline-block; }
    /* line 1824, src/assets/scss/_classes.scss */
    .owl-carousel .owl-dots .owl-dot span {
      background-color: #000;
      opacity: 0.3;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      display: inline-block;
      margin: 0 0.5rem;
      transition: 0.3s ease; }
    /* line 1835, src/assets/scss/_classes.scss */
    .owl-carousel .owl-dots .owl-dot.active span {
      opacity: 0.8; }

/********
--- P ---
********/
/* line 1848, src/assets/scss/_classes.scss */
.pac-logo:after {
  display: none; }

/* line 1854, src/assets/scss/_classes.scss */
.page .hero-wrapper {
  position: relative; }
  /* line 1856, src/assets/scss/_classes.scss */
  .page .hero-wrapper .background {
    background-color: #fff; }

/* line 1861, src/assets/scss/_classes.scss */
.page.sub-page .page-title {
  padding-top: 3rem;
  padding-bottom: 1rem; }

/* line 1866, src/assets/scss/_classes.scss */
.page.sub-page .form.hero-form {
  padding-top: 2rem; }
  /* line 1868, src/assets/scss/_classes.scss */
  .page.sub-page .form.hero-form .main-search-form {
    background-color: #f2f2f2;
    padding: 5rem 3rem 1rem 3rem;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
    position: relative; }
    /* line 1874, src/assets/scss/_classes.scss */
    .page.sub-page .form.hero-form .main-search-form:before {
      border-style: solid;
      border-width: 0 0.45rem 0.6rem 0.45rem;
      border-color: transparent transparent #f2f2f2 transparent;
      position: absolute;
      top: -0.6rem;
      right: 1.5rem;
      content: ''; }
    /* line 1884, src/assets/scss/_classes.scss */
    .page.sub-page .form.hero-form .main-search-form .form-group label {
      font-size: 1.8rem;
      top: -3.7rem; }
    /* line 1889, src/assets/scss/_classes.scss */
    .page.sub-page .form.hero-form .main-search-form .geo-location.input-group-addon {
      height: 3.9rem; }

/* line 1896, src/assets/scss/_classes.scss */
.page > .content {
  background-color: #f2f2f2;
  z-index: 1; }
  /* line 1899, src/assets/scss/_classes.scss */
  .page > .content:after {
    background-image: url("../../assets/img/footer-overlay.png");
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    width: 100%;
    height: 4.2rem;
    left: 0;
    content: '';
    position: absolute; }

/* line 1913, src/assets/scss/_classes.scss */
.panel {
  background-color: #fff;
  box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 30px; }
  /* line 1917, src/assets/scss/_classes.scss */
  .panel .panel-title {
    margin-bottom: 0; }
    /* line 1919, src/assets/scss/_classes.scss */
    .panel .panel-title a {
      display: block;
      padding: 20px; }
      /* line 1922, src/assets/scss/_classes.scss */
      .panel .panel-title a i {
        margin-right: 5px; }
  /* line 1927, src/assets/scss/_classes.scss */
  .panel .panel-body {
    padding: 20px; }
  /* line 1930, src/assets/scss/_classes.scss */
  .panel .horizontal-input-title {
    margin-top: 15px; }

/* line 1935, src/assets/scss/_classes.scss */
.page-pagination {
  text-align: center;
  margin: 6rem 0 3rem 0; }
  /* line 1938, src/assets/scss/_classes.scss */
  .page-pagination > nav {
    display: inline-block; }
    /* line 1940, src/assets/scss/_classes.scss */
    .page-pagination > nav .pagination {
      margin-bottom: 0; }
      /* line 1944, src/assets/scss/_classes.scss */
      .page-pagination > nav .pagination .page-item.active .page-link {
        background-color: #2d39b5;
        color: #fff;
        box-shadow: 0 0.1rem 2rem rgba(0, 0, 0, 0.15); }
      /* line 1950, src/assets/scss/_classes.scss */
      .page-pagination > nav .pagination .page-item .page-link {
        color: #000;
        width: 4rem;
        line-height: 4rem;
        padding: inherit;
        border: none;
        background-color: transparent;
        border-radius: 0.3rem;
        font-size: 1.6rem;
        margin: 0 0.2rem; }
        /* line 1960, src/assets/scss/_classes.scss */
        .page-pagination > nav .pagination .page-item .page-link:hover {
          background-color: #fff;
          box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08); }
        /* line 1964, src/assets/scss/_classes.scss */
        .page-pagination > nav .pagination .page-item .page-link i {
          font-size: 1.2rem;
          color: rgba(0, 0, 0, 0.5); }

/* line 1974, src/assets/scss/_classes.scss */
.pricing {
  position: relative;
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: center;
  padding: 30px 20px; }
  /* line 1980, src/assets/scss/_classes.scss */
  .pricing.box {
    background-color: #fff; }
    /* line 1982, src/assets/scss/_classes.scss */
    .pricing.box.featured {
      background-color: #000;
      color: #fff; }
      /* line 1986, src/assets/scss/_classes.scss */
      .pricing.box.featured ul li.available,
      .pricing.box.featured ul li.not-available,
      .pricing.box.featured ul li {
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.2); }
  /* line 1996, src/assets/scss/_classes.scss */
  .pricing.featured:not(.box) figure {
    background-color: #2d39b5;
    color: #fff; }
  /* line 2001, src/assets/scss/_classes.scss */
  .pricing.description {
    box-shadow: none;
    background-color: transparent;
    text-align: left;
    padding-left: 0; }
  /* line 2007, src/assets/scss/_classes.scss */
  .pricing h2 {
    opacity: 0.7;
    color: inherit;
    font-size: 30px;
    font-weight: lighter; }
  /* line 2013, src/assets/scss/_classes.scss */
  .pricing figure {
    box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08);
    border-radius: 100%;
    background-color: #ededed;
    width: 75px;
    height: 75px;
    position: absolute;
    top: 5px;
    right: -10px;
    text-align: center;
    line-height: 75px;
    color: #2d39b5;
    font-size: 18px;
    font-weight: bold; }
  /* line 2028, src/assets/scss/_classes.scss */
  .pricing ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 20px;
    margin-top: 20px; }
    /* line 2033, src/assets/scss/_classes.scss */
    .pricing ul li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      line-height: 55px; }
      /* line 2036, src/assets/scss/_classes.scss */
      .pricing ul li:last-child {
        border-bottom: none; }
      /* line 2039, src/assets/scss/_classes.scss */
      .pricing ul li.available {
        color: #2d39b5; }
      /* line 2042, src/assets/scss/_classes.scss */
      .pricing ul li.not-available {
        color: rgba(0, 0, 0, 0.5); }
      /* line 2045, src/assets/scss/_classes.scss */
      .pricing ul li i {
        font-size: 20px; }

/* line 2053, src/assets/scss/_classes.scss */
.profile-image .image {
  width: 100%;
  height: 25.5rem;
  border-radius: 50%;
  box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
  text-align: center; }
  /* line 2060, src/assets/scss/_classes.scss */
  .profile-image .image img {
    height: 100%; }

/********
--- R ---
********/
/* line 2070, src/assets/scss/_classes.scss */
.rating {
  margin-bottom: 0.5rem; }
  /* line 2072, src/assets/scss/_classes.scss */
  .rating i {
    font-size: 1rem;
    opacity: 0.4;
    padding: 0 0.1rem; }
    /* line 2076, src/assets/scss/_classes.scss */
    .rating i.active {
      color: #2d39b5;
      opacity: 1; }

/* line 2083, src/assets/scss/_classes.scss */
.read-more {
  overflow: hidden;
  transition: 1s ease;
  padding: 2rem 2rem 3rem 2rem;
  margin-right: -2rem;
  margin-left: -2rem;
  margin-bottom: 2rem;
  position: relative; }
  /* line 2091, src/assets/scss/_classes.scss */
  .read-more:after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 10rem;
    width: 100%;
    content: '';
    pointer-events: none;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f2f2f2+0,f2f2f2+100&0+0,1+66 */
    background: -moz-linear-gradient(top, rgba(242, 242, 242, 0) 0%, #f2f2f2 66%, #f2f2f2 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(242, 242, 242, 0) 0%, #f2f2f2 66%, #f2f2f2 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(242, 242, 242, 0) 0%, #f2f2f2 66%, #f2f2f2 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f2f2f2', endColorstr='#f2f2f2',GradientType=0 );
    /* IE6-9 */ }

/*------ Ribbon ------*/
/* line 2113, src/assets/scss/_classes.scss */
.ribbon-featured {
  position: absolute;
  top: -1rem;
  right: 1.5rem;
  z-index: 1; }
  /* line 2118, src/assets/scss/_classes.scss */
  .ribbon-featured .ribbon-content {
    box-shadow: 0 0.1rem rgba(0, 0, 0, 0.15);
    background-color: #38b52d;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 1.1rem;
    z-index: 1;
    padding: 0.7rem; }
    /* line 2127, src/assets/scss/_classes.scss */
    .ribbon-featured .ribbon-content:after {
      background-color: #38b52d;
      width: 0.5rem;
      height: 100%;
      content: '';
      position: absolute;
      top: 0.5rem;
      right: -0.5rem; }
    /* line 2136, src/assets/scss/_classes.scss */
    .ribbon-featured .ribbon-content:before {
      background-color: #38b52d;
      width: 1rem;
      height: 1rem;
      position: absolute;
      top: 0;
      right: -0.5rem;
      content: '';
      border-top-right-radius: 50%; }
  /* line 2147, src/assets/scss/_classes.scss */
  .ribbon-featured .ribbon-start,
  .ribbon-featured .ribbon-start::after {
    background: #38b52d;
    content: '';
    display: inline-block;
    height: 1rem;
    width: 0.5rem;
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
    right: -0.5rem;
    position: absolute;
    z-index: 1;
    top: 100%; }
  /* line 2161, src/assets/scss/_classes.scss */
  .ribbon-featured .ribbon-start::after {
    background: rgba(0, 0, 0, 0.3);
    z-index: 2;
    right: 0;
    top: 0; }
  /* line 2167, src/assets/scss/_classes.scss */
  .ribbon-featured .ribbon-end {
    height: 100%;
    width: 1.5rem;
    position: absolute;
    top: 0;
    left: -1.5rem; }
    /* line 2173, src/assets/scss/_classes.scss */
    .ribbon-featured .ribbon-end:after, .ribbon-featured .ribbon-end:before,
    .ribbon-featured .ribbon-end .ribbon-shadow::after,
    .ribbon-featured .ribbon-end .ribbon-shadow::before {
      border-style: solid;
      position: absolute;
      top: 0;
      left: 0;
      content: ''; }
    /* line 2183, src/assets/scss/_classes.scss */
    .ribbon-featured .ribbon-end:before,
    .ribbon-featured .ribbon-end .ribbon-shadow::before {
      border-width: 0 1.5rem 1.5rem 0;
      border-color: transparent #38b52d transparent transparent; }
    /* line 2188, src/assets/scss/_classes.scss */
    .ribbon-featured .ribbon-end:after,
    .ribbon-featured .ribbon-end .ribbon-shadow::after {
      border-width: 0 0 1.5rem 1.5rem;
      border-color: transparent transparent #38b52d transparent;
      bottom: 0; }
    /* line 2194, src/assets/scss/_classes.scss */
    .ribbon-featured .ribbon-end .ribbon-shadow::before {
      border-color: transparent rgba(0, 0, 0, 0.15) transparent transparent;
      top: 0.1rem;
      z-index: -1; }
    /* line 2199, src/assets/scss/_classes.scss */
    .ribbon-featured .ribbon-end .ribbon-shadow::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.15) transparent;
      bottom: -0.1rem;
      z-index: -1; }

/********
--- S ---
********/
/* line 2211, src/assets/scss/_classes.scss */
.section-title {
  padding-bottom: 4rem; }
  /* line 2213, src/assets/scss/_classes.scss */
  .section-title h2 {
    margin-bottom: 1rem; }
  /* line 2219, src/assets/scss/_classes.scss */
  .section-title .chosen-container:hover .chosen-single, .section-title .chosen-container.chosen-container-active .chosen-single {
    background-color: #fff; }
  /* line 2223, src/assets/scss/_classes.scss */
  .section-title .chosen-container .chosen-single {
    background-color: transparent;
    box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.05); }
  /* line 2228, src/assets/scss/_classes.scss */
  .section-title .selectize-input {
    background-color: transparent; }
    /* line 2230, src/assets/scss/_classes.scss */
    .section-title .selectize-input:hover, .section-title .selectize-input.chosen-container-active {
      background-color: #fff; }

/* line 2238, src/assets/scss/_classes.scss */
.sidebar section {
  margin-bottom: 4rem; }

/* line 2242, src/assets/scss/_classes.scss */
.sidebar .sidebar-form label {
  display: block; }

/* line 2245, src/assets/scss/_classes.scss */
.sidebar .sidebar-form .alternative-search-form {
  padding-top: 2rem; }

/* line 2250, src/assets/scss/_classes.scss */
.sidebar .sidebar-post {
  margin-bottom: 3rem;
  display: table;
  width: 100%; }
  /* line 2254, src/assets/scss/_classes.scss */
  .sidebar .sidebar-post .background-image {
    width: 10rem;
    height: 10rem;
    display: block;
    box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08);
    border-radius: 0.4rem;
    float: left; }
  /* line 2262, src/assets/scss/_classes.scss */
  .sidebar .sidebar-post .description {
    margin-left: 12rem; }
    /* line 2264, src/assets/scss/_classes.scss */
    .sidebar .sidebar-post .description h4 {
      margin-bottom: 1.5rem; }
    /* line 2267, src/assets/scss/_classes.scss */
    .sidebar .sidebar-post .description .meta {
      font-size: 1.1rem;
      opacity: 0.6; }
      /* line 2270, src/assets/scss/_classes.scss */
      .sidebar .sidebar-post .description .meta a {
        font-weight: 700; }

/* line 2277, src/assets/scss/_classes.scss */
.sidebar .sidebar-list {
  padding-left: 0; }
  /* line 2279, src/assets/scss/_classes.scss */
  .sidebar .sidebar-list li {
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1); }
    /* line 2281, src/assets/scss/_classes.scss */
    .sidebar .sidebar-list li a {
      display: block;
      padding: 1rem 0; }
      /* line 2284, src/assets/scss/_classes.scss */
      .sidebar .sidebar-list li a span {
        float: right;
        font-size: 1rem;
        opacity: 0.5;
        line-height: 2rem; }

/* line 2296, src/assets/scss/_classes.scss */
.side-nav a {
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
  padding: 1.5rem 0 1.5rem 0; }
  /* line 2299, src/assets/scss/_classes.scss */
  .side-nav a:last-child {
    border: none; }

/* line 2306, src/assets/scss/_classes.scss */
.social i {
  font-size: 1.8rem;
  margin-right: 0.5rem; }

/* line 2312, src/assets/scss/_classes.scss */
.sources {
  font-style: italic;
  font-weight: 300;
  font-size: 1.1rem; }

/* line 2318, src/assets/scss/_classes.scss */
.project__header {
  padding: 1.5rem 0; }

/********
--- T ---
********/
/* line 2326, src/assets/scss/_classes.scss */
.tag {
  background-color: #000;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  border-radius: 0.2rem;
  padding: 0.3rem 0.5rem;
  font-family: "Varela Round", sans-serif;
  white-space: nowrap; }
  /* line 2335, src/assets/scss/_classes.scss */
  .tag:hover {
    background-color: #2d39b5; }

/* line 2342, src/assets/scss/_classes.scss */
.tags.framed .tag {
  background-color: transparent;
  border: 0.1rem solid rgba(0, 0, 0, 0.1); }

/* line 2349, src/assets/scss/_classes.scss */
.tooltip {
  font-size: 1.2rem; }

/* line 2354, src/assets/scss/_classes.scss */
.thumbnail-toggle a {
  font-size: 1.5rem;
  margin-left: 0.2rem;
  padding: 0.8rem;
  border-radius: 0.3rem;
  width: 4rem;
  display: inline-block;
  text-align: center; }
  /* line 2362, src/assets/scss/_classes.scss */
  .thumbnail-toggle a:hover {
    background-color: rgba(0, 0, 0, 0.05); }
  /* line 2365, src/assets/scss/_classes.scss */
  .thumbnail-toggle a.active {
    background-color: #000;
    color: #fff; }

/********
--- Animations ---
********/
@keyframes underline-animation {
  0% {
    width: 100%; }
  30% {
    width: 0%; }
  60% {
    width: 100%;
    background-color: #2d39b5; }
  100% {
    left: 0;
    background-color: #2d39b5; } }

@keyframes show-form-slide-animation {
  0% {
    opacity: 0;
    transform: translateY(0.5rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*! noUiSlider - 7.0.10 - 2014-12-27 14:50:47 */
/* line 2406, src/assets/scss/_classes.scss */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/* line 2416, src/assets/scss/_classes.scss */
.noUi-target {
  position: relative;
  direction: ltr; }

/* line 2420, src/assets/scss/_classes.scss */
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative; }

/* line 2425, src/assets/scss/_classes.scss */
.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0; }

/* line 2432, src/assets/scss/_classes.scss */
.noUi-handle {
  position: relative;
  z-index: 1; }

/* line 2436, src/assets/scss/_classes.scss */
.noUi-stacking .noUi-handle {
  z-index: 10; }

/* line 2439, src/assets/scss/_classes.scss */
.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s; }

/* line 2443, src/assets/scss/_classes.scss */
.noUi-state-drag * {
  cursor: inherit !important; }

/* line 2446, src/assets/scss/_classes.scss */
.noUi-base {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* line 2450, src/assets/scss/_classes.scss */
.noUi-horizontal {
  height: 18px; }

/* line 2453, src/assets/scss/_classes.scss */
.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px; }

/* line 2459, src/assets/scss/_classes.scss */
.noUi-vertical {
  width: 18px; }

/* line 2462, src/assets/scss/_classes.scss */
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px; }

/* line 2468, src/assets/scss/_classes.scss */
.noUi-background {
  background: #fafafa;
  box-shadow: inset 0 1px 1px #f0f0f0; }

/* line 2472, src/assets/scss/_classes.scss */
.noUi-connect {
  background: #3fb8af;
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
  -webkit-transition: background 450ms;
  transition: background 450ms; }

/* line 2478, src/assets/scss/_classes.scss */
.noUi-origin {
  border-radius: 2px; }

/* line 2481, src/assets/scss/_classes.scss */
.noUi-target {
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb; }

/* line 2486, src/assets/scss/_classes.scss */
.noUi-target.noUi-connect {
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45), 0 3px 6px -5px #bbb; }

/* line 2489, src/assets/scss/_classes.scss */
.noUi-dragable {
  cursor: w-resize; }

/* line 2492, src/assets/scss/_classes.scss */
.noUi-vertical .noUi-dragable {
  cursor: n-resize; }

/* line 2495, src/assets/scss/_classes.scss */
.noUi-handle {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  cursor: default;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb; }

/* line 2502, src/assets/scss/_classes.scss */
.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb; }

/* line 2505, src/assets/scss/_classes.scss */
.noUi-handle:after,
.noUi-handle:before {
  content: '';
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #e8e7e6;
  left: 14px;
  top: 6px; }

/* line 2516, src/assets/scss/_classes.scss */
.noUi-handle:after {
  left: 17px; }

/* line 2519, src/assets/scss/_classes.scss */
.noUi-vertical .noUi-handle:after,
.noUi-vertical .noUi-handle:before {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px; }

/* line 2526, src/assets/scss/_classes.scss */
.noUi-vertical .noUi-handle:after {
  top: 17px; }

/* line 2529, src/assets/scss/_classes.scss */
[disabled] .noUi-connect,
[disabled].noUi-connect {
  background: #b8b8b8; }

/* line 2533, src/assets/scss/_classes.scss */
[disabled] .noUi-handle {
  cursor: not-allowed; }

/* line 2537, src/assets/scss/_classes.scss */
.ui-slider {
  border-radius: 0px;
  box-shadow: none;
  border: none;
  background-color: transparent; }
  /* line 2542, src/assets/scss/_classes.scss */
  .ui-slider .noUi-base {
    border-radius: 0px;
    box-shadow: none;
    border: none;
    height: 2px;
    background-color: #e4e4e2;
    margin-top: 6px; }
    /* line 2549, src/assets/scss/_classes.scss */
    .ui-slider .noUi-base .noUi-connect {
      box-shadow: none;
      background-color: #2d39b5; }
    /* line 2553, src/assets/scss/_classes.scss */
    .ui-slider .noUi-base .noUi-background {
      box-shadow: none;
      background-color: #e4e4e2; }
    /* line 2557, src/assets/scss/_classes.scss */
    .ui-slider .noUi-base .noUi-handle {
      transition: 0.2s;
      border-radius: 50%;
      box-shadow: none;
      border: 2px solid #2d39b5;
      background-color: #2d39b5;
      cursor: pointer;
      height: 10px;
      width: 10px;
      left: 0px;
      top: -4px; }
      /* line 2568, src/assets/scss/_classes.scss */
      .ui-slider .noUi-base .noUi-handle:before, .ui-slider .noUi-base .noUi-handle:after {
        display: none; }
      /* line 2572, src/assets/scss/_classes.scss */
      .ui-slider .noUi-base .noUi-handle:hover, .ui-slider .noUi-base .noUi-handle.noUi-active {
        box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.07); }
      /* line 2576, src/assets/scss/_classes.scss */
      .ui-slider .noUi-base .noUi-handle.noUi-handle-upper {
        left: -8px; }
  /* line 2581, src/assets/scss/_classes.scss */
  .ui-slider .values {
    font-size: 10px; }
    /* line 2583, src/assets/scss/_classes.scss */
    .ui-slider .values input {
      background-color: transparent;
      border: none;
      width: 49%; }
    /* line 2588, src/assets/scss/_classes.scss */
    .ui-slider .values input:first-child {
      float: left; }
    /* line 2591, src/assets/scss/_classes.scss */
    .ui-slider .values input:last-child {
      float: right;
      text-align: right; }

/*2. Elements*/
/* line 1, src/assets/scss/_elements.scss */
a {
  color: #000;
  transition: 0.3s color ease, 0.3s background-color ease, 0.3s box-shadow ease; }
  /* line 4, src/assets/scss/_elements.scss */
  a:hover, a:focus, a:active {
    outline: none !important;
    text-decoration: none; }
  /* line 10, src/assets/scss/_elements.scss */
  a:hover {
    color: #2d39b5; }
  /* line 14, src/assets/scss/_elements.scss */
  a.icon i {
    color: #2d39b5;
    margin-right: 1rem;
    font-size: 1.2rem; }
  /* line 21, src/assets/scss/_elements.scss */
  a.underline:hover::after {
    animation-name: underline-animation;
    animation-duration: 0.7s;
    animation-fill-mode: forwards; }
  /* line 26, src/assets/scss/_elements.scss */
  a.underline:after {
    background-color: #000;
    width: 100%;
    height: 0.1rem;
    content: '';
    position: absolute;
    bottom: 0;
    right: 0; }
  /* line 36, src/assets/scss/_elements.scss */
  a.text-uppercase {
    font-size: 1.1rem;
    font-weight: 600; }
  /* line 40, src/assets/scss/_elements.scss */
  a .appendix {
    opacity: 0.4;
    margin-left: 0.5rem; }
  /* line 44, src/assets/scss/_elements.scss */
  a.link {
    color: #2d39b5; }
  /* line 48, src/assets/scss/_elements.scss */
  a.nav-link.active {
    color: #2d39b5; }
    /* line 50, src/assets/scss/_elements.scss */
    a.nav-link.active i {
      color: #2d39b5;
      opacity: 1; }
  /* line 55, src/assets/scss/_elements.scss */
  a.nav-link i {
    color: inherit;
    opacity: 0.3; }

/* line 62, src/assets/scss/_elements.scss */
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Varela Round", sans-serif;
  font-weight: 500;
  position: relative; }

/* line 71, src/assets/scss/_elements.scss */
html {
  font-size: 62.5%; }

/* line 75, src/assets/scss/_elements.scss */
body {
  font-size: 1.4rem;
  background-color: #f2f2f2;
  height: 100%;
  overflow-x: hidden; }

/* line 82, src/assets/scss/_elements.scss */
dl {
  margin-bottom: 1rem; }
  /* line 84, src/assets/scss/_elements.scss */
  dl dt {
    float: left;
    padding: 0.2rem 0; }
  /* line 88, src/assets/scss/_elements.scss */
  dl dd {
    text-align: right;
    padding: 0.2rem 0; }

/* line 94, src/assets/scss/_elements.scss */
h1 {
  font-family: "Varela Round", sans-serif;
  font-size: 3.6rem; }

/* line 99, src/assets/scss/_elements.scss */
h2 {
  font-family: "Varela Round", sans-serif;
  font-size: 2.4rem;
  margin-bottom: 4rem;
  padding-top: 1rem; }

/* line 106, src/assets/scss/_elements.scss */
h3 {
  font-family: "Varela Round", sans-serif;
  font-size: 1.8rem;
  margin-bottom: 2rem; }

/* line 113, src/assets/scss/_elements.scss */
h4.location:before {
  font-family: 'fontawesome';
  content: '\f041';
  position: relative;
  font-size: 1.3rem;
  opacity: 0.6;
  margin-right: 0.2rem; }

/* line 124, src/assets/scss/_elements.scss */
hr {
  margin-top: 2rem;
  margin-bottom: 2rem; }

/* line 130, src/assets/scss/_elements.scss */
figure.with-icon {
  position: relative;
  padding-left: 2.5rem; }
  /* line 133, src/assets/scss/_elements.scss */
  figure.with-icon i {
    color: #2d39b5;
    position: absolute;
    line-height: 2rem;
    left: 0; }

/* line 142, src/assets/scss/_elements.scss */
mark,
.mark {
  background-color: #ffdc23; }

/* line 147, src/assets/scss/_elements.scss */
p {
  opacity: 0.65;
  font-weight: normal; }

/* line 155, src/assets/scss/_elements.scss */
ul.columns-2,
ol.columns-2,
dl.columns-2 {
  column-count: 2; }
  /* line 157, src/assets/scss/_elements.scss */
  ul.columns-2 dd,
  ul.columns-2 li,
  ol.columns-2 dd,
  ol.columns-2 li,
  dl.columns-2 dd,
  dl.columns-2 li {
    margin-right: 1rem; }

/* line 162, src/assets/scss/_elements.scss */
ul.columns-3,
ol.columns-3,
dl.columns-3 {
  column-count: 3; }
  /* line 164, src/assets/scss/_elements.scss */
  ul.columns-3 dd,
  ul.columns-3 li,
  ol.columns-3 dd,
  ol.columns-3 li,
  dl.columns-3 dd,
  dl.columns-3 li {
    margin-right: 1rem; }

/* line 169, src/assets/scss/_elements.scss */
ul.columns-4,
ol.columns-4,
dl.columns-4 {
  column-count: 4; }
  /* line 171, src/assets/scss/_elements.scss */
  ul.columns-4 dd,
  ul.columns-4 li,
  ol.columns-4 dd,
  ol.columns-4 li,
  dl.columns-4 dd,
  dl.columns-4 li {
    margin-right: 1rem; }

/* line 176, src/assets/scss/_elements.scss */
ul.features-checkboxes,
ol.features-checkboxes,
dl.features-checkboxes {
  padding-left: 0;
  list-style: none; }
  /* line 179, src/assets/scss/_elements.scss */
  ul.features-checkboxes li,
  ol.features-checkboxes li,
  dl.features-checkboxes li {
    margin-bottom: 1.5rem;
    position: relative;
    padding-left: 4rem; }
    /* line 183, src/assets/scss/_elements.scss */
    ul.features-checkboxes li:before,
    ol.features-checkboxes li:before,
    dl.features-checkboxes li:before {
      width: 2rem;
      height: 2rem;
      background-color: #2d39b5;
      border-radius: 0.3rem;
      content: '';
      display: inline-block;
      position: absolute;
      left: 0; }
    /* line 193, src/assets/scss/_elements.scss */
    ul.features-checkboxes li:after,
    ol.features-checkboxes li:after,
    dl.features-checkboxes li:after {
      font-family: 'fontawesome';
      color: #fff;
      content: '\f00c';
      position: absolute;
      line-height: 1.8rem;
      font-size: 1rem;
      left: 0.5rem;
      top: 0; }

/* line 207, src/assets/scss/_elements.scss */
section {
  position: relative; }

/* line 211, src/assets/scss/_elements.scss */
header,
footer {
  position: relative; }

/*3. Forms*/
/* line 1, src/assets/scss/_forms.scss */
.btn {
  display: inline-block;
  position: relative;
  transition: 0.3s ease;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  border-radius: 0.3rem;
  /*padding: .8rem 1.6rem;*/
  padding: 1.4rem 1.6rem;
  border-width: 0.1rem;
  outline: none !important;
  cursor: pointer; }
  /* line 13, src/assets/scss/_forms.scss */
  .btn:hover, .btn:focus, .btn:active {
    outline: none !important; }
  /* line 18, src/assets/scss/_forms.scss */
  .btn.btn-primary {
    background-color: #2d39b5;
    border-color: #2d39b5; }
    /* line 21, src/assets/scss/_forms.scss */
    .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active {
      color: #fff;
      box-shadow: 0 0.1rem 1.5rem rgba(0, 0, 0, 0.4); }
  /* line 28, src/assets/scss/_forms.scss */
  .btn.btn-rounded {
    border-radius: 3rem; }
  /* line 31, src/assets/scss/_forms.scss */
  .btn.btn-framed {
    background-color: transparent; }
    /* line 33, src/assets/scss/_forms.scss */
    .btn.btn-framed.btn-primary {
      color: #2d39b5; }
      /* line 35, src/assets/scss/_forms.scss */
      .btn.btn-framed.btn-primary:hover, .btn.btn-framed.btn-primary:focus, .btn.btn-framed.btn-primary:active {
        background-color: #2d39b5;
        border-color: #2d39b5;
        box-shadow: none;
        color: #fff; }
    /* line 44, src/assets/scss/_forms.scss */
    .btn.btn-framed.btn-secondary {
      color: #868e96; }
      /* line 46, src/assets/scss/_forms.scss */
      .btn.btn-framed.btn-secondary:hover, .btn.btn-framed.btn-secondary:focus, .btn.btn-framed.btn-secondary:active {
        background-color: #868e96;
        color: #fff; }
    /* line 53, src/assets/scss/_forms.scss */
    .btn.btn-framed.btn-success {
      color: #1e7e34; }
      /* line 55, src/assets/scss/_forms.scss */
      .btn.btn-framed.btn-success:hover, .btn.btn-framed.btn-success:focus, .btn.btn-framed.btn-success:active {
        background-color: #1e7e34;
        color: #fff; }
    /* line 62, src/assets/scss/_forms.scss */
    .btn.btn-framed.btn-danger {
      color: #dc3545; }
      /* line 64, src/assets/scss/_forms.scss */
      .btn.btn-framed.btn-danger:hover, .btn.btn-framed.btn-danger:focus, .btn.btn-framed.btn-danger:active {
        background-color: #dc3545;
        color: #fff; }
    /* line 71, src/assets/scss/_forms.scss */
    .btn.btn-framed.btn-warning {
      color: #ffc107; }
      /* line 73, src/assets/scss/_forms.scss */
      .btn.btn-framed.btn-warning:hover, .btn.btn-framed.btn-warning:focus, .btn.btn-framed.btn-warning:active {
        background-color: #ffc107;
        color: #fff; }
    /* line 80, src/assets/scss/_forms.scss */
    .btn.btn-framed.btn-info {
      color: #17a2b8; }
      /* line 82, src/assets/scss/_forms.scss */
      .btn.btn-framed.btn-info:hover, .btn.btn-framed.btn-info:focus, .btn.btn-framed.btn-info:active {
        background-color: #17a2b8;
        color: #fff; }
    /* line 89, src/assets/scss/_forms.scss */
    .btn.btn-framed.btn-light {
      color: #000;
      border-color: rgba(0, 0, 0, 0.1); }
      /* line 92, src/assets/scss/_forms.scss */
      .btn.btn-framed.btn-light:hover, .btn.btn-framed.btn-light:focus, .btn.btn-framed.btn-light:active {
        background-color: rgba(0, 0, 0, 0.1); }
  /* line 99, src/assets/scss/_forms.scss */
  .btn.small {
    /*padding: 0.9rem 1rem; font-size: 1.5rem;*/
    padding: 0.7rem 1rem;
    font-size: 1.2rem; }
  /* line 104, src/assets/scss/_forms.scss */
  .btn.large {
    font-size: 2.4rem;
    padding: 1.2rem 2.4rem; }
    /* line 108, src/assets/scss/_forms.scss */
    .btn.large.icon i {
      margin: 0 1rem;
      font-size: 1.5rem; }
  /* line 115, src/assets/scss/_forms.scss */
  .btn.icon i {
    margin: 0 0.5rem;
    font-size: 1.2rem;
    vertical-align: middle; }
  /* line 121, src/assets/scss/_forms.scss */
  .btn.btn-light {
    color: #000; }

/* line 126, src/assets/scss/_forms.scss */
select {
  width: 100%;
  padding: 1.3rem;
  border-radius: 0.3rem;
  background-color: #fff;
  box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.1);
  border: 0.1rem solid rgba(0, 0, 0, 0.15); }
  /* line 133, src/assets/scss/_forms.scss */
  select.small {
    padding: 0.9rem; }
  /* line 136, src/assets/scss/_forms.scss */
  select.selectized {
    display: block !important;
    visibility: hidden;
    position: absolute;
    z-index: -9999; }

/* line 144, src/assets/scss/_forms.scss */
.selectize-control {
  display: inline-block;
  width: 100%; }
  /* line 148, src/assets/scss/_forms.scss */
  .selectize-control.small .selectize-input {
    padding: 1.05rem; }
  /* line 151, src/assets/scss/_forms.scss */
  .selectize-control.small .selectize-dropdown {
    top: 3.9rem !important; }
  /* line 157, src/assets/scss/_forms.scss */
  .selectize-control.multi .selectize-input.has-items {
    padding: 1.1rem; }
  /* line 162, src/assets/scss/_forms.scss */
  .selectize-control .selectize-input {
    transition: 0.3s ease;
    border-radius: 0.3rem;
    font-weight: 500;
    padding: 1.7rem;
    height: auto;
    background-image: none;
    background-color: #fff;
    position: relative;
    line-height: inherit;
    box-shadow: 0 0.2rem 1rem 0rem rgba(0, 0, 0, 0.1);
    border: 0.1rem solid rgba(0, 0, 0, 0.15); }
    /* line 174, src/assets/scss/_forms.scss */
    .selectize-control .selectize-input .item {
      cursor: pointer; }
      /* line 176, src/assets/scss/_forms.scss */
      .selectize-control .selectize-input .item[data-value=''] {
        opacity: 0.4; }
    /* line 180, src/assets/scss/_forms.scss */
    .selectize-control .selectize-input input[type='text'] {
      height: 1.4rem;
      transition: none; }
    /* line 184, src/assets/scss/_forms.scss */
    .selectize-control .selectize-input.full {
      cursor: pointer !important; }
      /* line 186, src/assets/scss/_forms.scss */
      .selectize-control .selectize-input.full input {
        cursor: pointer !important;
        width: 0;
        color: transparent; }
  /* line 193, src/assets/scss/_forms.scss */
  .selectize-control .selectize-dropdown {
    margin-top: -0.4rem;
    opacity: 0;
    transition: 0.3s ease;
    width: 100% !important;
    top: 4.9rem !important; }
    /* line 199, src/assets/scss/_forms.scss */
    .selectize-control .selectize-dropdown.opening {
      opacity: 1; }
    /* line 202, src/assets/scss/_forms.scss */
    .selectize-control .selectize-dropdown .selectize-dropdown-content {
      max-height: 30rem; }
      /* line 204, src/assets/scss/_forms.scss */
      .selectize-control .selectize-dropdown .selectize-dropdown-content [data-value=''] {
        opacity: 0.4; }
      /* line 208, src/assets/scss/_forms.scss */
      .selectize-control .selectize-dropdown .selectize-dropdown-content .option.active {
        background-color: rgba(0, 0, 0, 0.03);
        transition: 0.3s ease; }
  /* line 215, src/assets/scss/_forms.scss */
  .selectize-control .selectize-dropdown [data-selectable],
  .selectize-control .selectize-dropdown .optgroup-header {
    padding: 0.8rem 1.2rem; }

/* line 221, src/assets/scss/_forms.scss */
input[type='text'],
input[type='email'],
input[type='date'],
input[type='time'],
input[type='search'],
input[type='password'],
input[type='number'],
input[type='tel'],
textarea.form-control {
  box-shadow: inset 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  border: 0.1rem solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem;
  color: #363636;
  transition: 0.3s;
  transform-style: preserve-3d;
  -webkit-appearance: none;
  background-color: #fff;
  font-size: 1.4rem;
  outline: none !important;
  width: 100%;
  height: inherit;
  padding: 1.6rem; }
  /* line 243, src/assets/scss/_forms.scss */
  input[type='text']:active, input[type='text']:focus, input[type='text']:hover,
  input[type='email']:active,
  input[type='email']:focus,
  input[type='email']:hover,
  input[type='date']:active,
  input[type='date']:focus,
  input[type='date']:hover,
  input[type='time']:active,
  input[type='time']:focus,
  input[type='time']:hover,
  input[type='search']:active,
  input[type='search']:focus,
  input[type='search']:hover,
  input[type='password']:active,
  input[type='password']:focus,
  input[type='password']:hover,
  input[type='number']:active,
  input[type='number']:focus,
  input[type='number']:hover,
  input[type='tel']:active,
  input[type='tel']:focus,
  input[type='tel']:hover,
  textarea.form-control:active,
  textarea.form-control:focus,
  textarea.form-control:hover {
    box-shadow: inset 0 0 0 0.1rem rgba(0, 0, 0, 0);
    border: 0.1rem solid rgba(0, 0, 0, 0.25); }
  /* line 249, src/assets/scss/_forms.scss */
  input[type='text'].small,
  input[type='email'].small,
  input[type='date'].small,
  input[type='time'].small,
  input[type='search'].small,
  input[type='password'].small,
  input[type='number'].small,
  input[type='tel'].small,
  textarea.form-control.small {
    padding: 1rem; }

/* line 253, src/assets/scss/_forms.scss */
#input-location {
  padding-right: 5rem; }

/* line 257, src/assets/scss/_forms.scss */
.icheckbox,
.iradio {
  box-shadow: inset 0 0 0 0.1rem rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  background-color: #fff;
  transition: 0.2s ease;
  cursor: pointer;
  position: relative;
  display: inline-block;
  height: 2rem;
  margin-right: 1rem;
  width: 2rem;
  top: -0.1rem;
  margin-bottom: 1.5rem;
  vertical-align: top; }
  /* line 272, src/assets/scss/_forms.scss */
  .icheckbox:after,
  .iradio:after {
    font-family: 'fontawesome';
    color: #fff;
    content: '\f00c';
    position: absolute;
    line-height: 1.8rem;
    font-size: 1rem;
    left: 0.5rem; }
  /* line 281, src/assets/scss/_forms.scss */
  .icheckbox.checked,
  .iradio.checked {
    box-shadow: inset 0 0 0 1rem #2d39b5; }

/* line 285, src/assets/scss/_forms.scss */
.iradio {
  border-radius: 50%; }
  /* line 287, src/assets/scss/_forms.scss */
  .iradio:after {
    display: none; }
  /* line 290, src/assets/scss/_forms.scss */
  .iradio.checked {
    box-shadow: inset 0 0 0 0.6rem #2d39b5; }

/* line 295, src/assets/scss/_forms.scss */
label {
  padding-bottom: 0.5rem;
  margin-right: 1rem; }
  /* line 298, src/assets/scss/_forms.scss */
  label.framed {
    padding: 1rem 1.2rem;
    border-radius: 0.3rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: 0.3s ease; }
    /* line 304, src/assets/scss/_forms.scss */
    label.framed:hover {
      background-color: #fff;
      box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08); }
    /* line 308, src/assets/scss/_forms.scss */
    label.framed > div {
      margin-bottom: 0; }
    /* line 311, src/assets/scss/_forms.scss */
    label.framed.active {
      background-color: #fff; }
  /* line 316, src/assets/scss/_forms.scss */
  label.required:after {
    content: '*';
    margin-left: 0.2rem;
    color: red; }

/* line 326, src/assets/scss/_forms.scss */
.form.inputs-fluid .form-row {
  display: table;
  width: 100%; }
  /* line 329, src/assets/scss/_forms.scss */
  .form.inputs-fluid .form-row .form-group {
    display: table-cell;
    width: auto;
    vertical-align: top; }

/* line 337, src/assets/scss/_forms.scss */
.form.submitted .status {
  transform: scale(1); }

/* line 340, src/assets/scss/_forms.scss */
.form.submitted .form-group,
.form.submitted .input-group {
  pointer-events: none; }

/* line 344, src/assets/scss/_forms.scss */
.form.submitted .btn[type='submit'] {
  pointer-events: none;
  opacity: 0.5; }

/* line 349, src/assets/scss/_forms.scss */
.form .status {
  transform: scale(0.1);
  transition: 0.3s ease;
  top: -2rem;
  right: -2rem;
  position: absolute; }
  /* line 355, src/assets/scss/_forms.scss */
  .form .status .status-icon {
    border-radius: 50%;
    transition: 0.3s ease;
    width: 4rem;
    height: 4rem;
    background-color: grey;
    color: #fff;
    text-align: center;
    line-height: 4rem; }
    /* line 364, src/assets/scss/_forms.scss */
    .form .status .status-icon.valid {
      background-color: #50aa8d; }
    /* line 367, src/assets/scss/_forms.scss */
    .form .status .status-icon.invalid {
      background-color: #e45544; }
  /* line 371, src/assets/scss/_forms.scss */
  .form .status i {
    font-size: 1.8rem; }

/* line 375, src/assets/scss/_forms.scss */
.form .form-group {
  position: relative; }

/* line 378, src/assets/scss/_forms.scss */
.form label.error {
  position: absolute;
  left: 0;
  bottom: -3rem;
  background-color: red;
  color: #fff;
  padding: 0.3rem; }

/* line 387, src/assets/scss/_forms.scss */
.form ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(0, 0, 0, 0.4); }

/* line 391, src/assets/scss/_forms.scss */
.form :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(0, 0, 0, 0.4); }

/* line 395, src/assets/scss/_forms.scss */
.form ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(0, 0, 0, 0.4); }

/* line 399, src/assets/scss/_forms.scss */
.form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.4); }

/* line 403, src/assets/scss/_forms.scss */
.form ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.4); }

/* line 408, src/assets/scss/_forms.scss */
.form.hero-form {
  padding-top: 10rem; }
  /* line 412, src/assets/scss/_forms.scss */
  .form.hero-form .main-search-form .form-group {
    position: relative; }
    /* line 414, src/assets/scss/_forms.scss */
    .form.hero-form .main-search-form .form-group label {
      font-family: "Varela Round", sans-serif;
      font-size: 2.4rem;
      color: #2d39b5;
      position: absolute;
      top: -5rem;
      font-weight: normal; }
  /* line 424, src/assets/scss/_forms.scss */
  .form.hero-form [type='submit'] {
    padding: 1.4rem 1.6rem; }
    /* line 426, src/assets/scss/_forms.scss */
    .form.hero-form [type='submit'].small {
      padding: 0.9rem 1rem;
      font-size: 1.5rem; }

/* line 433, src/assets/scss/_forms.scss */
.form .alternative-search-form {
  padding-top: 1rem;
  z-index: 1;
  position: relative; }
  /* line 438, src/assets/scss/_forms.scss */
  .form .alternative-search-form .collapse.show .wrapper {
    opacity: 1;
    transform: scale(1); }
  /* line 443, src/assets/scss/_forms.scss */
  .form .alternative-search-form .wrapper {
    opacity: 0;
    transition: 0.1s ease;
    transform: scale(0.98);
    margin-top: 2rem;
    box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
    border: 0.1rem solid rgba(0, 0, 0, 0.1);
    padding: 4rem;
    padding-bottom: 2rem;
    border-radius: 3px;
    background-color: #fff;
    position: relative; }
    /* line 455, src/assets/scss/_forms.scss */
    .form .alternative-search-form .wrapper:before {
      border-style: solid;
      border-width: 0 0.45rem 0.6rem 0.45rem;
      border-color: transparent transparent #fff transparent;
      position: absolute;
      top: -0.6rem;
      left: 1.8rem;
      content: ''; }
    /* line 464, src/assets/scss/_forms.scss */
    .form .alternative-search-form .wrapper:after {
      border-style: solid;
      border-width: 0 0.55rem 0.7rem 0.55rem;
      border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
      position: absolute;
      top: -0.7rem;
      left: 1.7rem;
      content: '';
      z-index: -1; }

/* line 477, src/assets/scss/_forms.scss */
.form.form-submit .icheckbox,
.form.form-submit .iradio {
  box-shadow: inset 0 0 0 0.2rem rgba(0, 0, 0, 0.3); }

/* line 482, src/assets/scss/_forms.scss */
.form.form-submit .icheckbox.checked {
  box-shadow: inset 0 0 0 1rem #2d39b5; }

/* line 487, src/assets/scss/_forms.scss */
.form.form-submit .iradio.checked {
  box-shadow: inset 0 0 0 0.6rem #2d39b5; }

/* line 494, src/assets/scss/_forms.scss */
.form-group {
  margin-bottom: 1.5rem; }
  /* line 496, src/assets/scss/_forms.scss */
  .form-group .input-group-addon {
    position: absolute;
    bottom: 1.6rem;
    border: none;
    right: 0;
    padding: 0 1.3rem;
    background-color: transparent;
    transition: 0.3s ease;
    font-size: 1.4rem;
    opacity: 0.5; }
    /* line 506, src/assets/scss/_forms.scss */
    .form-group .input-group-addon.geo-location, .form-group .input-group-addon.search-icon {
      color: #2d39b5;
      cursor: pointer;
      opacity: 1;
      position: absolute;
      bottom: 0;
      height: 5.4rem;
      padding: 1.3rem;
      right: 0;
      background-color: transparent;
      transition: 0.3s ease;
      font-size: 2rem;
      border: none; }
      /* line 520, src/assets/scss/_forms.scss */
      .form-group .input-group-addon.geo-location:hover, .form-group .input-group-addon.search-icon:hover {
        background-color: rgba(0, 0, 0, 0.1); }
    /* line 524, src/assets/scss/_forms.scss */
    .form-group .input-group-addon.small {
      bottom: 1.1rem; }
  /* line 528, src/assets/scss/_forms.scss */
  .form-group label {
    font-size: 1.3rem;
    margin-bottom: 0.8rem;
    font-weight: 600; }
    /* line 532, src/assets/scss/_forms.scss */
    .form-group label.framed {
      font-weight: normal; }

/* line 538, src/assets/scss/_forms.scss */
.form-slides {
  position: relative;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 0.4rem;
  z-index: 1; }
  /* line 544, src/assets/scss/_forms.scss */
  .form-slides .form-slide {
    display: none;
    transition: 0.3s ease;
    position: relative; }
    /* line 548, src/assets/scss/_forms.scss */
    .form-slides .form-slide.default {
      display: block;
      text-align: center;
      padding: 4rem 2rem; }
      /* line 552, src/assets/scss/_forms.scss */
      .form-slides .form-slide.default h3 {
        margin-top: 1rem;
        margin-bottom: 1rem; }
    /* line 557, src/assets/scss/_forms.scss */
    .form-slides .form-slide.active {
      display: block;
      visibility: visible;
      opacity: 1;
      animation-name: show-form-slide-animation;
      animation-duration: 0.3s;
      animation-fill-mode: forwards; }
    /* line 565, src/assets/scss/_forms.scss */
    .form-slides .form-slide h3 {
      margin-bottom: 3rem;
      font-size: 1.8rem; }
    /* line 569, src/assets/scss/_forms.scss */
    .form-slides .form-slide h4 {
      padding-top: 2rem;
      padding-bottom: 2rem; }
    /* line 573, src/assets/scss/_forms.scss */
    .form-slides .form-slide .category-icon {
      position: absolute;
      box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08);
      background-color: #fff;
      border-radius: 50%;
      width: 6rem;
      height: 6rem;
      top: -3rem;
      right: 0;
      overflow: hidden;
      text-align: center;
      line-height: 6rem; }
      /* line 585, src/assets/scss/_forms.scss */
      .form-slides .form-slide .category-icon img {
        height: 3rem; }
  /* line 591, src/assets/scss/_forms.scss */
  .form-slides#category-tabs:before {
    border-style: solid;
    border-width: 0.9rem 1rem 0.9rem 0;
    border-color: transparent #f2f2f2 transparent transparent;
    content: '';
    position: absolute;
    left: -0.9rem;
    top: 5rem;
    z-index: 1; }
  /* line 601, src/assets/scss/_forms.scss */
  .form-slides#category-tabs:after {
    border-style: solid;
    border-width: 0.9rem 1rem 0.9rem 0;
    border-color: transparent rgba(0, 0, 0, 0.1) transparent transparent;
    content: '';
    position: absolute;
    left: -1.1rem;
    top: 5rem; }

/* line 613, src/assets/scss/_forms.scss */
.file-upload {
  position: relative;
  height: 10rem;
  margin-bottom: 2rem;
  margin-top: 2rem; }
  /* line 618, src/assets/scss/_forms.scss */
  .file-upload .file-upload-input {
    border-radius: 0.4rem;
    width: 100%;
    border: 0.2rem dashed rgba(0, 0, 0, 0.2);
    height: 10rem;
    text-align: center;
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 10rem 0 0 0;
    overflow: hidden;
    z-index: 1;
    transition: 0.3s ease; }
    /* line 631, src/assets/scss/_forms.scss */
    .file-upload .file-upload-input:hover {
      border-color: rgba(0, 0, 0, 0.4);
      background-color: rgba(0, 0, 0, 0.05); }
  /* line 636, src/assets/scss/_forms.scss */
  .file-upload span {
    position: absolute;
    top: 0;
    bottom: 0;
    line-height: 10.5rem;
    width: 100%;
    text-align: center;
    margin: auto;
    z-index: 0;
    left: 0;
    font-size: 1.8rem;
    color: rgba(0, 0, 0, 0.5); }
    /* line 648, src/assets/scss/_forms.scss */
    .file-upload span i {
      color: #2d39b5;
      margin-right: 1rem; }

/* line 656, src/assets/scss/_forms.scss */
.file-upload-previews > .MultiFile-label {
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0.03);
  display: inline-block;
  border: 0.2rem solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  position: relative;
  margin-right: 1rem;
  width: 100%; }

/* line 666, src/assets/scss/_forms.scss */
.file-upload-previews span.MultiFile-label {
  box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08);
  position: relative;
  text-align: center;
  display: inline-block;
  margin: 1rem; }
  /* line 672, src/assets/scss/_forms.scss */
  .file-upload-previews span.MultiFile-label .MultiFile-title {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    padding: 1rem;
    bottom: 0;
    font-size: 1.2rem;
    text-align: center;
    width: 100%; }
  /* line 682, src/assets/scss/_forms.scss */
  .file-upload-previews span.MultiFile-label .MultiFile-preview {
    max-width: 20rem !important;
    max-height: 15rem !important; }

/* line 687, src/assets/scss/_forms.scss */
.file-upload-previews .MultiFile-remove {
  box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  color: transparent;
  position: absolute;
  background-color: red;
  width: 2rem;
  height: 2rem;
  top: -1rem;
  right: -1rem;
  z-index: 1; }
  /* line 698, src/assets/scss/_forms.scss */
  .file-upload-previews .MultiFile-remove:after {
    font-family: 'fontawesome';
    content: '\f00d';
    color: #fff;
    top: -0.2rem;
    position: relative;
    font-size: 1rem; }

/* line 709, src/assets/scss/_forms.scss */
.file-uploaded-images .image {
  height: 15rem;
  display: inline-block;
  margin-bottom: 1.8rem;
  margin-right: 1.5rem;
  position: relative; }
  /* line 715, src/assets/scss/_forms.scss */
  .file-uploaded-images .image figure {
    box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    cursor: pointer;
    background-color: red;
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: -1rem;
    top: -1rem;
    content: '';
    text-align: center;
    line-height: 1.5rem; }
    /* line 728, src/assets/scss/_forms.scss */
    .file-uploaded-images .image figure i {
      color: #fff;
      font-size: 1rem; }
  /* line 733, src/assets/scss/_forms.scss */
  .file-uploaded-images .image img {
    height: 100%; }

/* line 739, src/assets/scss/_forms.scss */
.single-file-input {
  overflow: hidden;
  position: relative;
  margin-top: 2rem;
  font-size: 1.2rem;
  text-align: center; }
  /* line 745, src/assets/scss/_forms.scss */
  .single-file-input input[type='file'] {
    padding-top: 4rem;
    position: absolute;
    width: 100%;
    cursor: pointer;
    outline: none;
    z-index: 1; }
  /* line 754, src/assets/scss/_forms.scss */
  .single-file-input div i {
    margin-left: 0.5rem; }

/* line 760, src/assets/scss/_forms.scss */
textarea.form-control {
  line-height: 2rem; }

/*4. Universal classes*/
/* line 1, src/assets/scss/_universal-classes.scss */
.background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

/* line 9, src/assets/scss/_universal-classes.scss */
.background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  overflow: hidden;
  width: 100%;
  height: 100%; }
  /* line 16, src/assets/scss/_universal-classes.scss */
  .background-image img {
    display: none; }
  /* line 19, src/assets/scss/_universal-classes.scss */
  .background-image.original-size {
    background-size: inherit; }
  /* line 22, src/assets/scss/_universal-classes.scss */
  .background-image.background-repeat-x {
    background-repeat: repeat-x; }
  /* line 25, src/assets/scss/_universal-classes.scss */
  .background-image.background-repeat-y {
    background-repeat: repeat-y; }

/* line 30, src/assets/scss/_universal-classes.scss */
.block {
  padding-top: 5rem;
  padding-bottom: 5rem; }
  /* line 33, src/assets/scss/_universal-classes.scss */
  .block section {
    margin-bottom: 6rem; }

/* line 38, src/assets/scss/_universal-classes.scss */
.box {
  background-color: #fff;
  box-shadow: 0 0.1rem 2rem rgba(0, 0, 0, 0.15);
  padding: 3rem;
  border-radius: 0.3rem;
  position: relative; }

/* line 46, src/assets/scss/_universal-classes.scss */
.center {
  text-align: center; }

/* line 51, src/assets/scss/_universal-classes.scss */
.has-dark-background h1,
.has-dark-background h2,
.has-dark-background h3,
.has-dark-background h4,
.has-dark-background h5,
.has-dark-background h6,
.has-dark-background p,
.has-dark-background a {
  color: #fff; }

/* line 61, src/assets/scss/_universal-classes.scss */
.has-dark-background .navbar .navbar-nav .show > .nav-link,
.has-dark-background .navbar .navbar-nav .active > .nav-link,
.has-dark-background .navbar .navbar-nav .nav-link.show,
.has-dark-background .navbar .navbar-nav .nav-link.active,
.has-dark-background .main-navigation .navbar ul.navbar-nav > .nav-item > a:not(.btn) {
  color: #fff; }

/* line 69, src/assets/scss/_universal-classes.scss */
.has-dark-background .main-navigation .navbar ul.navbar-nav > li.nav-item.has-child > a.nav-link:after {
  color: #fff;
  opacity: 0.7; }

/* line 73, src/assets/scss/_universal-classes.scss */
.has-dark-background .main-navigation .btn:not(.btn-light) {
  color: #fff; }

/* line 76, src/assets/scss/_universal-classes.scss */
.has-dark-background .main-navigation .navbar {
  border-color: rgba(255, 255, 255, 0.3); }

/* line 81, src/assets/scss/_universal-classes.scss */
.has-dark-background .chosen-container a {
  color: inherit; }

/* line 87, src/assets/scss/_universal-classes.scss */
.has-dark-background .page-title a:after {
  background-color: #fff; }

/* line 92, src/assets/scss/_universal-classes.scss */
.has-dark-background .form.hero-form .main-search-form .form-group label {
  color: #fff; }

/* line 96, src/assets/scss/_universal-classes.scss */
.has-dark-background .form-group > label {
  color: #fff; }

/* line 102, src/assets/scss/_universal-classes.scss */
.no-shadow {
  box-shadow: none;
  text-shadow: none; }

/* line 107, src/assets/scss/_universal-classes.scss */
.pointer-events-none {
  pointer-events: none; }

/* line 111, src/assets/scss/_universal-classes.scss */
.text-caps {
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 700; }

/* line 117, src/assets/scss/_universal-classes.scss */
.height-100px {
  height: 100px !important; }

/* line 120, src/assets/scss/_universal-classes.scss */
.height-150px {
  height: 150px !important; }

/* line 123, src/assets/scss/_universal-classes.scss */
.height-200px {
  height: 200px !important; }

/* line 126, src/assets/scss/_universal-classes.scss */
.height-250px {
  height: 250px !important; }

/* line 129, src/assets/scss/_universal-classes.scss */
.height-300px {
  height: 300px !important; }

/* line 132, src/assets/scss/_universal-classes.scss */
.height-350px {
  height: 350px !important; }

/* line 135, src/assets/scss/_universal-classes.scss */
.height-400px {
  height: 400px !important; }

/* line 138, src/assets/scss/_universal-classes.scss */
.height-450px {
  height: 450px !important; }

/* line 141, src/assets/scss/_universal-classes.scss */
.height-500px {
  height: 500px !important; }

/* line 145, src/assets/scss/_universal-classes.scss */
.width-10 {
  width: 10% !important; }

/* line 148, src/assets/scss/_universal-classes.scss */
.width-20 {
  width: 20% !important; }

/* line 151, src/assets/scss/_universal-classes.scss */
.width-25 {
  width: 25% !important; }

/* line 154, src/assets/scss/_universal-classes.scss */
.width-30 {
  width: 30% !important; }

/* line 157, src/assets/scss/_universal-classes.scss */
.width-33 {
  width: 33% !important; }

/* line 160, src/assets/scss/_universal-classes.scss */
.width-40 {
  width: 40% !important; }

/* line 163, src/assets/scss/_universal-classes.scss */
.width-50 {
  width: 50% !important; }

/* line 166, src/assets/scss/_universal-classes.scss */
.width-60 {
  width: 60% !important; }

/* line 169, src/assets/scss/_universal-classes.scss */
.width-70 {
  width: 70% !important; }

/* line 172, src/assets/scss/_universal-classes.scss */
.width-80 {
  width: 80% !important; }

/* line 175, src/assets/scss/_universal-classes.scss */
.width-90 {
  width: 90% !important; }

/* line 178, src/assets/scss/_universal-classes.scss */
.width-100 {
  width: 100% !important; }

/* line 182, src/assets/scss/_universal-classes.scss */
.width-10px {
  width: 10px !important; }

/* line 185, src/assets/scss/_universal-classes.scss */
.width-50px {
  width: 50px !important; }

/* line 188, src/assets/scss/_universal-classes.scss */
.width-100px {
  width: 100px !important; }

/* line 191, src/assets/scss/_universal-classes.scss */
.width-150px {
  width: 150px !important; }

/* line 194, src/assets/scss/_universal-classes.scss */
.width-200px {
  width: 200px !important; }

/* line 197, src/assets/scss/_universal-classes.scss */
.width-250px {
  width: 250px !important; }

/* line 200, src/assets/scss/_universal-classes.scss */
.width-300px {
  width: 300px !important; }

/* line 204, src/assets/scss/_universal-classes.scss */
.opacity-5 {
  opacity: 0.05; }

/* line 207, src/assets/scss/_universal-classes.scss */
.opacity-10 {
  opacity: 0.1; }

/* line 210, src/assets/scss/_universal-classes.scss */
.opacity-20 {
  opacity: 0.2; }

/* line 213, src/assets/scss/_universal-classes.scss */
.opacity-30 {
  opacity: 0.3; }

/* line 216, src/assets/scss/_universal-classes.scss */
.opacity-40 {
  opacity: 0.4; }

/* line 219, src/assets/scss/_universal-classes.scss */
.opacity-50 {
  opacity: 0.5; }

/* line 222, src/assets/scss/_universal-classes.scss */
.opacity-60 {
  opacity: 0.6; }

/* line 225, src/assets/scss/_universal-classes.scss */
.opacity-70 {
  opacity: 0.7; }

/* line 228, src/assets/scss/_universal-classes.scss */
.opacity-80 {
  opacity: 0.8; }

/* line 231, src/assets/scss/_universal-classes.scss */
.opacity-90 {
  opacity: 0.9; }

/* line 235, src/assets/scss/_universal-classes.scss */
.text-align-right {
  text-align: right; }

/* line 238, src/assets/scss/_universal-classes.scss */
.text-align-left {
  text-align: left; }

/*5. Responsive*/
/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 1200px and up
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media (min-width: 1200px) {
  /* line 8, src/assets/scss/_responsive.scss */
  .items.grid.grid-xl-5-items .item,
  .items.masonry.grid-xl-5-items .item {
    width: 20%; }
  /* line 13, src/assets/scss/_responsive.scss */
  .items.grid.grid-xl-4-items .item,
  .items.masonry.grid-xl-4-items .item {
    width: 25%; }
  /* line 18, src/assets/scss/_responsive.scss */
  .items.grid.grid-xl-3-items .item,
  .items.masonry.grid-xl-3-items .item {
    width: 33.33%; }
  /* line 23, src/assets/scss/_responsive.scss */
  .items.grid.grid-xl-2-items .item,
  .items.masonry.grid-xl-2-items .item {
    width: 50%; } }

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 992px - 1199px
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media (min-width: 992px) and (max-width: 1199px) {
  /* line 37, src/assets/scss/_responsive.scss */
  .items.grid.grid-lg-5-items .item,
  .items.masonry.grid-lg-5-items .item {
    width: 20%; }
  /* line 42, src/assets/scss/_responsive.scss */
  .items.grid.grid-lg-4-items .item,
  .items.masonry.grid-lg-4-items .item {
    width: 25%; }
  /* line 47, src/assets/scss/_responsive.scss */
  .items.grid.grid-lg-3-items .item,
  .items.masonry.grid-lg-3-items .item {
    width: 33.33%; }
  /* line 52, src/assets/scss/_responsive.scss */
  .items.grid.grid-lg-2-items .item,
  .items.masonry.grid-lg-2-items .item {
    width: 50%; }
  /* line 59, src/assets/scss/_responsive.scss */
  .owl-carousel.full-width-carousel .item {
    width: 90rem;
    height: 50rem; } }

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 768px - 991px
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media (min-width: 768px) and (max-width: 991px) {
  /* line 71, src/assets/scss/_responsive.scss */
  h1 {
    font-size: 3rem; }
  /* line 74, src/assets/scss/_responsive.scss */
  h2 {
    font-size: 2rem; }
  /* line 81, src/assets/scss/_responsive.scss */
  .form.hero-form .main-search-form .form-group label {
    top: -4rem;
    font-size: 1.8rem; }
  /* line 94, src/assets/scss/_responsive.scss */
  .items.grid.grid.grid-md-5-items .item, .items.grid.masonry.grid-md-5-items .item,
  .items.masonry.grid.grid-md-5-items .item,
  .items.masonry.masonry.grid-md-5-items .item {
    width: 20%; }
  /* line 99, src/assets/scss/_responsive.scss */
  .items.grid.grid.grid-md-4-items .item, .items.grid.masonry.grid-md-4-items .item,
  .items.masonry.grid.grid-md-4-items .item,
  .items.masonry.masonry.grid-md-4-items .item {
    width: 25%; }
  /* line 104, src/assets/scss/_responsive.scss */
  .items.grid.grid.grid-md-3-items .item, .items.grid.masonry.grid-md-3-items .item,
  .items.masonry.grid.grid-md-3-items .item,
  .items.masonry.masonry.grid-md-3-items .item {
    width: 33.33%; }
  /* line 109, src/assets/scss/_responsive.scss */
  .items.grid.grid.grid-md-2-items .item, .items.grid.masonry.grid-md-2-items .item,
  .items.masonry.grid.grid-md-2-items .item,
  .items.masonry.masonry.grid-md-2-items .item {
    width: 50%; }
  /* line 116, src/assets/scss/_responsive.scss */
  .hero .page-title {
    padding-top: 4rem;
    padding-bottom: 1rem; }
  /* line 122, src/assets/scss/_responsive.scss */
  .hero .main-navigation .navbar ul.navbar-nav {
    position: relative;
    text-align: right; }
    /* line 125, src/assets/scss/_responsive.scss */
    .hero .main-navigation .navbar ul.navbar-nav a:not(.btn) {
      padding: 1.2rem 2rem; }
    /* line 130, src/assets/scss/_responsive.scss */
    .hero .main-navigation .navbar ul.navbar-nav > li.nav-item:first-child a.nav-link {
      border-top: none; }
    /* line 134, src/assets/scss/_responsive.scss */
    .hero .main-navigation .navbar ul.navbar-nav > li.nav-item a.nav-link {
      border-top: 0.1rem solid rgba(0, 0, 0, 0.04); }
    /* line 137, src/assets/scss/_responsive.scss */
    .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child {
      position: relative;
      opacity: 1;
      width: 100%;
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.05);
      margin-top: 0;
      transform: translateY(0);
      right: 0;
      border-radius: 0; }
      /* line 147, src/assets/scss/_responsive.scss */
      .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child:before, .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child:after {
        display: none; }
      /* line 151, src/assets/scss/_responsive.scss */
      .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li {
        border-bottom: none; }
        /* line 153, src/assets/scss/_responsive.scss */
        .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li ul {
          right: 0;
          transform: translateX(0); }
        /* line 157, src/assets/scss/_responsive.scss */
        .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li a.nav-link {
          padding: 1rem 2.5rem; }
        /* line 162, src/assets/scss/_responsive.scss */
        .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li.has-child > a.nav-link:after {
          content: '\f0d7';
          left: inherit;
          right: 1rem; }
    /* line 175, src/assets/scss/_responsive.scss */
    .hero .main-navigation .navbar ul.navbar-nav li.nav-item.has-child > a.nav-link:after {
      bottom: inherit;
      top: 1.5rem; }
    /* line 181, src/assets/scss/_responsive.scss */
    .hero .main-navigation .navbar ul.navbar-nav li.nav-item.has-child.hover > ul.child {
      height: auto; }
    /* line 186, src/assets/scss/_responsive.scss */
    .hero .main-navigation .navbar ul.navbar-nav li.nav-item ul.child {
      height: 0;
      overflow: hidden; }
  /* line 197, src/assets/scss/_responsive.scss */
  .owl-carousel.full-width-carousel .item {
    width: 69rem;
    height: 40rem; } }

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// max to 767px
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media (max-width: 767px) {
  /* line 208, src/assets/scss/_responsive.scss */
  body::before {
    display: none;
    content: 'xs'; }
  /* line 212, src/assets/scss/_responsive.scss */
  h1 {
    font-size: 3rem; }
  /* line 215, src/assets/scss/_responsive.scss */
  h2 {
    font-size: 2rem; }
  /* line 218, src/assets/scss/_responsive.scss */
  .d-xs-grid {
    display: grid !important; }
  /* line 221, src/assets/scss/_responsive.scss */
  .float-xs-none {
    float: none !important; }
  /* line 226, src/assets/scss/_responsive.scss */
  .block section {
    margin-bottom: 4rem; }
  /* line 230, src/assets/scss/_responsive.scss */
  .d-xs-none {
    display: none !important; }
  /* line 233, src/assets/scss/_responsive.scss */
  .admin-controls {
    top: 3rem !important;
    bottom: auto !important; }
  /* line 238, src/assets/scss/_responsive.scss */
  .author.big {
    padding-top: 28rem;
    position: relative; }
    /* line 241, src/assets/scss/_responsive.scss */
    .author.big .author-image {
      position: absolute;
      text-align: center;
      top: 0;
      left: 0;
      right: 0;
      margin: auto; }
    /* line 249, src/assets/scss/_responsive.scss */
    .author.big .author-description {
      margin-left: 0; }
  /* line 255, src/assets/scss/_responsive.scss */
  .blog-posts-navigation .prev,
  .blog-posts-navigation .next {
    display: block; }
  /* line 260, src/assets/scss/_responsive.scss */
  .footer {
    position: relative; }
  /* line 265, src/assets/scss/_responsive.scss */
  .form-slides#category-tabs:before, .form-slides#category-tabs:after {
    display: none; }
  /* line 275, src/assets/scss/_responsive.scss */
  .items.grid[class*='grid-'] .item, .items.masonry[class*='grid-'] .item {
    width: 100%;
    display: block; }
  /* line 284, src/assets/scss/_responsive.scss */
  .items.list .item .wrapper, .items.list.compact .item .wrapper {
    min-height: inherit; }
    /* line 286, src/assets/scss/_responsive.scss */
    .items.list .item .wrapper .image, .items.list.compact .item .wrapper .image {
      position: relative;
      padding-right: 0; }
      /* line 289, src/assets/scss/_responsive.scss */
      .items.list .item .wrapper .image .background-image, .items.list.compact .item .wrapper .image .background-image {
        height: 28rem;
        width: auto; }
        /* line 292, src/assets/scss/_responsive.scss */
        .items.list .item .wrapper .image .background-image:before, .items.list.compact .item .wrapper .image .background-image:before {
          opacity: 0.9;
          height: 14rem; }
  /* line 299, src/assets/scss/_responsive.scss */
  .items.list .item h3, .items.list.compact .item h3 {
    left: 2rem;
    bottom: 8rem;
    top: inherit; }
    /* line 303, src/assets/scss/_responsive.scss */
    .items.list .item h3 a:not(.category), .items.list.compact .item h3 a:not(.category) {
      color: #fff; }
    /* line 306, src/assets/scss/_responsive.scss */
    .items.list .item h3 .tag, .items.list.compact .item h3 .tag {
      word-wrap: none; }
      /* line 308, src/assets/scss/_responsive.scss */
      .items.list .item h3 .tag.category, .items.list.compact .item h3 .tag.category {
        top: -3rem;
        left: 0;
        bottom: auto; }
      /* line 313, src/assets/scss/_responsive.scss */
      .items.list .item h3 .tag:not(.category), .items.list.compact .item h3 .tag:not(.category) {
        position: absolute;
        top: -15rem;
        bottom: auto;
        left: 0;
        background-color: #fff;
        border: none; }
  /* line 323, src/assets/scss/_responsive.scss */
  .items.list .item h4, .items.list.compact .item h4 {
    top: 20rem;
    left: 2rem; }
    /* line 326, src/assets/scss/_responsive.scss */
    .items.list .item h4 a, .items.list.compact .item h4 a {
      color: #fff; }
      /* line 328, src/assets/scss/_responsive.scss */
      .items.list .item h4 a:before, .items.list.compact .item h4 a:before {
        color: #fff; }
    /* line 333, src/assets/scss/_responsive.scss */
    .items.list .item h4.location:before, .items.list.compact .item h4.location:before {
      color: #fff; }
  /* line 338, src/assets/scss/_responsive.scss */
  .items.list .item .price, .items.list.compact .item .price {
    top: 24.5rem;
    bottom: auto; }
  /* line 342, src/assets/scss/_responsive.scss */
  .items.list .item .description, .items.list.compact .item .description {
    position: relative;
    left: 0;
    padding: 4rem 2rem;
    height: 10rem; }
  /* line 348, src/assets/scss/_responsive.scss */
  .items.list .item .detail, .items.list.compact .item .detail {
    position: relative;
    right: -2rem; }
  /* line 352, src/assets/scss/_responsive.scss */
  .items.list .item .meta, .items.list.compact .item .meta {
    background-color: #f8f8f8;
    padding: 1.8rem;
    font-size: 1.2rem;
    width: 100%;
    white-space: nowrap;
    margin-top: -0.2rem;
    position: relative;
    text-align: left; }
    /* line 361, src/assets/scss/_responsive.scss */
    .items.list .item .meta:before, .items.list.compact .item .meta:before {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f8f8f8+0,f8f8f8+100&0+0,1+50 */
      background: -moz-linear-gradient(left, rgba(248, 248, 248, 0) 0%, #f8f8f8 50%, #f8f8f8 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(248, 248, 248, 0) 0%, #f8f8f8 50%, #f8f8f8 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(248, 248, 248, 0) 0%, #f8f8f8 50%, #f8f8f8 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f8f8f8', endColorstr='#f8f8f8',GradientType=1 );
      /* IE6-9 */
      height: 100%;
      width: 4rem;
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1; }
    /* line 381, src/assets/scss/_responsive.scss */
    .items.list .item .meta figure, .items.list.compact .item .meta figure {
      opacity: 0.6;
      margin-right: 2rem;
      display: inline-block; }
      /* line 385, src/assets/scss/_responsive.scss */
      .items.list .item .meta figure i, .items.list.compact .item .meta figure i {
        margin-right: 1rem; }
      /* line 388, src/assets/scss/_responsive.scss */
      .items.list .item .meta figure a, .items.list.compact .item .meta figure a {
        transition: 0.3s color ease; }
  /* line 393, src/assets/scss/_responsive.scss */
  .items.list .item .additional-info, .items.list.compact .item .additional-info {
    padding: 0 2rem;
    margin-left: 0;
    margin-bottom: 4rem; }
  /* line 402, src/assets/scss/_responsive.scss */
  .form.hero-form {
    padding-top: 1rem; }
    /* line 404, src/assets/scss/_responsive.scss */
    .form.hero-form .main-search-form {
      margin-bottom: 2rem; }
      /* line 407, src/assets/scss/_responsive.scss */
      .form.hero-form .main-search-form .form-group label {
        position: relative;
        font-size: 2rem;
        top: inherit; }
  /* line 419, src/assets/scss/_responsive.scss */
  .hero .main-navigation .navbar ul.navbar-nav {
    position: relative;
    text-align: right; }
    /* line 422, src/assets/scss/_responsive.scss */
    .hero .main-navigation .navbar ul.navbar-nav a:not(.btn) {
      padding: 1.2rem 2rem; }
    /* line 427, src/assets/scss/_responsive.scss */
    .hero .main-navigation .navbar ul.navbar-nav > li.nav-item:first-child a.nav-link {
      border-top: none; }
    /* line 431, src/assets/scss/_responsive.scss */
    .hero .main-navigation .navbar ul.navbar-nav > li.nav-item a.nav-link {
      border-top: 0.1rem solid rgba(0, 0, 0, 0.04); }
    /* line 434, src/assets/scss/_responsive.scss */
    .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child {
      position: relative;
      opacity: 1;
      width: 100%;
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.05);
      margin-top: 0;
      transform: translateY(0);
      right: 0;
      border-radius: 0; }
      /* line 444, src/assets/scss/_responsive.scss */
      .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child:before, .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child:after {
        display: none; }
      /* line 448, src/assets/scss/_responsive.scss */
      .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li {
        border-bottom: none; }
        /* line 450, src/assets/scss/_responsive.scss */
        .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li ul {
          right: 0;
          transform: translateX(0); }
        /* line 454, src/assets/scss/_responsive.scss */
        .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li a.nav-link {
          padding: 1rem 2.5rem; }
        /* line 459, src/assets/scss/_responsive.scss */
        .hero .main-navigation .navbar ul.navbar-nav > li.nav-item ul.child li.has-child > a.nav-link:after {
          content: '\f0d7';
          left: inherit;
          right: 1rem; }
    /* line 472, src/assets/scss/_responsive.scss */
    .hero .main-navigation .navbar ul.navbar-nav li.nav-item.has-child > a.nav-link:after {
      bottom: inherit;
      top: 1.5rem; }
    /* line 478, src/assets/scss/_responsive.scss */
    .hero .main-navigation .navbar ul.navbar-nav li.nav-item.has-child.hover > ul.child {
      height: auto; }
    /* line 483, src/assets/scss/_responsive.scss */
    .hero .main-navigation .navbar ul.navbar-nav li.nav-item ul.child {
      height: 0;
      overflow: hidden; }
  /* line 492, src/assets/scss/_responsive.scss */
  .hero .page-title .price {
    padding-top: 2rem;
    text-align: left; }
  /* line 498, src/assets/scss/_responsive.scss */
  .hero.has-dark-background a {
    color: #fff !important; }
  /* line 505, src/assets/scss/_responsive.scss */
  .categories-list li {
    width: 100%; }
  /* line 510, src/assets/scss/_responsive.scss */
  .feature-box {
    text-align: center; }
    /* line 512, src/assets/scss/_responsive.scss */
    .feature-box figure {
      display: inline-block; }
  /* line 517, src/assets/scss/_responsive.scss */
  .main-search-form {
    padding: 3rem !important; }
    /* line 520, src/assets/scss/_responsive.scss */
    .main-search-form .form-group label {
      top: inherit !important; }
  /* line 526, src/assets/scss/_responsive.scss */
  .profile-image {
    text-align: center; }
    /* line 528, src/assets/scss/_responsive.scss */
    .profile-image .image {
      display: inline-block;
      width: 25.5rem; }
  /* line 535, src/assets/scss/_responsive.scss */
  .sidebar .sidebar-form {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 2rem;
    border-radius: 0.3rem; }
  /* line 542, src/assets/scss/_responsive.scss */
  .side-nav {
    margin-bottom: 3rem; }
  /* line 546, src/assets/scss/_responsive.scss */
  .width-10px {
    width: 100% !important; }
  /* line 549, src/assets/scss/_responsive.scss */
  .width-50px {
    width: 100% !important; }
  /* line 552, src/assets/scss/_responsive.scss */
  .width-100px {
    width: 100% !important; }
  /* line 555, src/assets/scss/_responsive.scss */
  .width-150px {
    width: 100% !important; }
  /* line 558, src/assets/scss/_responsive.scss */
  .width-200px {
    width: 100% !important; }
  /* line 561, src/assets/scss/_responsive.scss */
  .width-250px {
    width: 100% !important; }
  /* line 564, src/assets/scss/_responsive.scss */
  .width-300px {
    width: 100% !important; }
  /* line 570, src/assets/scss/_responsive.scss */
  .owl-carousel.full-width-carousel .item {
    width: 100%;
    height: 100%; }
  /* line 576, src/assets/scss/_responsive.scss */
  .owl-carousel.gallery-carousel-thumbs .owl-thumb {
    height: 6rem; }
  /* line 582, src/assets/scss/_responsive.scss */
  .owl-carousel.full-width-carousel {
    top: -3.5rem; }
    /* line 584, src/assets/scss/_responsive.scss */
    .owl-carousel.full-width-carousel .owl-item {
      opacity: 1; }
  /* line 592, src/assets/scss/_responsive.scss */
  ul.columns-2,
  ol.columns-2,
  dl.columns-2 {
    column-count: 1; }
  /* line 595, src/assets/scss/_responsive.scss */
  ul.columns-3,
  ol.columns-3,
  dl.columns-3 {
    column-count: 1; }
  /* line 598, src/assets/scss/_responsive.scss */
  ul.columns-4,
  ol.columns-4,
  dl.columns-4 {
    column-count: 1; } }

/*@import "_colors.pcss";*/
