.background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  img {
    display: none;
  }
  &.original-size {
    background-size: inherit;
  }
  &.background-repeat-x {
    background-repeat: repeat-x;
  }
  &.background-repeat-y {
    background-repeat: repeat-y;
  }
}

.block {
  padding-top: 5rem;
  padding-bottom: 5rem;
  section {
    margin-bottom: 6rem;
  }
}

.box {
  background-color: $color-white;
  box-shadow: $shadow-big;
  padding: 3rem;
  border-radius: 0.3rem;
  position: relative;
}

.center {
  text-align: center;
}

.has-dark-background {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a {
    color: #fff;
  }
  .navbar .navbar-nav .show > .nav-link,
  .navbar .navbar-nav .active > .nav-link,
  .navbar .navbar-nav .nav-link.show,
  .navbar .navbar-nav .nav-link.active,
  .main-navigation .navbar ul.navbar-nav > .nav-item > a:not(.btn) {
    color: #fff;
  }
  .main-navigation {
    .navbar ul.navbar-nav > li.nav-item.has-child > a.nav-link:after {
      color: #fff;
      opacity: 0.7;
    }
    .btn:not(.btn-light) {
      color: #fff;
    }
    .navbar {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
  .chosen-container {
    a {
      color: inherit;
    }
  }
  .page-title {
    a {
      &:after {
        background-color: #fff;
      }
    }
  }
  .form.hero-form .main-search-form .form-group label {
    color: #fff;
  }
  .form-group {
    > label {
      color: #fff;
    }
  }
}

.no-shadow {
  box-shadow: none;
  text-shadow: none;
}

.pointer-events-none {
  pointer-events: none;
}

.text-caps {
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 700;
}

.height-100px {
  height: 100px !important;
}
.height-150px {
  height: 150px !important;
}
.height-200px {
  height: 200px !important;
}
.height-250px {
  height: 250px !important;
}
.height-300px {
  height: 300px !important;
}
.height-350px {
  height: 350px !important;
}
.height-400px {
  height: 400px !important;
}
.height-450px {
  height: 450px !important;
}
.height-500px {
  height: 500px !important;
}

.width-10 {
  width: 10% !important;
}
.width-20 {
  width: 20% !important;
}
.width-25 {
  width: 25% !important;
}
.width-30 {
  width: 30% !important;
}
.width-33 {
  width: 33% !important;
}
.width-40 {
  width: 40% !important;
}
.width-50 {
  width: 50% !important;
}
.width-60 {
  width: 60% !important;
}
.width-70 {
  width: 70% !important;
}
.width-80 {
  width: 80% !important;
}
.width-90 {
  width: 90% !important;
}
.width-100 {
  width: 100% !important;
}

.width-10px {
  width: 10px !important;
}
.width-50px {
  width: 50px !important;
}
.width-100px {
  width: 100px !important;
}
.width-150px {
  width: 150px !important;
}
.width-200px {
  width: 200px !important;
}
.width-250px {
  width: 250px !important;
}
.width-300px {
  width: 300px !important;
}

.opacity-5 {
  opacity: 0.05;
}
.opacity-10 {
  opacity: 0.1;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-90 {
  opacity: 0.9;
}

.text-align-right {
  text-align: right;
}
.text-align-left {
  text-align: left;
}
